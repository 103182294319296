import React, {useEffect, useState} from "react";
import {Modal, Pagination, Select, Table, Tooltip} from "antd";
import {actions as tasksActions} from "../../store/modules/allTasks/slice";
import {actions as deleteGodoActions} from "../../store/modules/deleteGodo/slice";
import {useDispatch, useSelector} from "react-redux";
import {selectTasksData} from "../../store/modules/allTasks/selector";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {useLocation, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

const {Option} = Select;

const CustomPagination = ({
                              total,
                              pageSize,
                              current,
                              onChange,
                              onPageSizeChange,
                          }) => {
    const handlePageSizeChange = (value) => {
        onPageSizeChange(value);
    };
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingTop: "16px",
                paddingLeft: "16px",
                gap: "16px",
            }}
        >
      <span className="table-info">{`${(current - 1) * pageSize + 1}-${Math.min(
          current * pageSize,
          total
      )} ${i18n.t("table_pagin_separator")} ${total} ${i18n.t("table_pagin_item")}`}</span>

            <Pagination
                total={total}
                pageSize={pageSize}
                current={current}
                onChange={onChange}
            />
            <Select
                className="select-per"
                value={pageSize}
                onChange={handlePageSizeChange}
                style={{width: 120, color: "#000000D9"}}
            >
                {[10, 20, 30, 40, 50].map((size) => (
                    <Option key={size} value={size}>
                        {size}/{i18n.t("table_pagin_page")}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

const TasksTable = () => {
    const location = useLocation();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const convertDuration = (minutes) => {
        if (minutes < 60) {
            return `${minutes} ${i18n.t("dashboard_one_godo_min")}`;
        } else {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            if (remainingMinutes === 0) {
                return `${hours} ${i18n.t("dashboard_one_godo_hours")}`;
            } else {
                return `${hours}:${remainingMinutes} ${i18n.t("dashboard_one_godo_hours")}`;
            }
        }
    };

    const columns = [
        {
            title: i18n.t("dashboard_table_name"),
            dataIndex: "title",
            render: (text, record) => (
                <a
                    onClick={() => {
                        navigate("/tasks/" + record.id);
                    }}
                >
                    {text}
                </a>
            ),
        },
        {
            title: i18n.t("dashboard_table_desc"),
            dataIndex: "description",
            sorter: {
                compare: (a, b) =>
                    a.description && b.description
                        ? a.description.localeCompare(b.description)
                        : 0,
            },
        },
        {
            title: i18n.t("dashboard_table_category"),
            dataIndex: "type",
            sorter: {
                compare: (a, b) =>
                    a.type && b.type ? a.type.localeCompare(b.type) : 0,
                multiple: 3,
            },
            render: (text, record) => {
                return (
                    <div className="category-table" style={{background: record.category.data.color, color: "white"}}>
                        {record.category.data.title}
                    </div>
                );
            },
        },
        {
            title: i18n.t("dashboard_table_status"),
            dataIndex: "status",
            sorter: {
                compare: (a, b) => {
                    const aHasActions = a.actions ? 1 : 0;
                    const bHasActions = b.actions ? 1 : 0;
                    return bHasActions - aHasActions;
                },
                multiple: 3,
            },
            render: (_, record) => <span>{renderStatus(record)}</span>,
        },
        {
            title: i18n.t("dashboard_table_user_count"),
            dataIndex: "count",
            sorter: {
                compare: (a, b) => {
                    const aHasActions = a.actions ? 1 : 0;
                    const bHasActions = b.actions ? 1 : 0;
                    return bHasActions - aHasActions;
                },
                multiple: 3,
            },
            render: (text, record) => {
                if (
                    record.actions &&
                    Array.isArray(record.actions.data) &&
                    record.actions.data.length > 1
                ) {
                    return (
                        <span
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                setModalOpen(true);
                                setModalData(record.actions.data);
                            }}
                        >
                {i18n.t("dashboard_table_multiple")} ({ record.actions.data.length })
            </span>
                    );
                } else if (
                    record.actions &&
                    Array.isArray(record.actions.data) &&
                    record.actions.data.length === 1
                ) {
                    return 1;
                } else {
                    return 0;
                }
            },

        },
        {
            title: i18n.t("dashboard_table_data"),
            dataIndex: "date",
            sorter: {
                compare: (a, b) =>
                    a.date && b.date ? new Date(a.date) - new Date(b.date) : 0,
                multiple: 1,
            },
        },
        {
            title: i18n.t("dashboard_table_duraction"),
            dataIndex: "duration",
            sorter: {
                compare: (a, b) => {
                    const durationA = a.duration || 0;
                    const durationB = b.duration || 0;
                    return durationA - durationB;
                },
                multiple: 1,
            },
            render: (text) => {
                if (text) {
                    const duration = convertDuration(text);
                    return duration;
                } else {
                    return "0";
                }
            },
        },
        {
            title: i18n.t("dashboard_table_actions"),
            dataIndex: "actions",
            render: (text, record) => (
                <div style={{display: "flex"}}>
                    <div
                        onClick={() => {
                            navigate("/edit/" + record.id + "?id=" + record.id);
                        }}
                        style={{marginRight: "12px", cursor: "pointer"}}
                    >
                        <Tooltip placement="top" title={"Edit"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="20"
                                height="20"
                                viewBox="0 0 50 50"
                            >
                                <path
                                    style={{fill: 'orange'}}
                                    d="M 46.574219 3.425781 C 45.625 2.476563 44.378906 2 43.132813 2 C 41.886719 2 40.640625 2.476563 39.691406 3.425781 C 39.691406 3.425781 39.621094 3.492188 39.53125 3.585938 C 39.523438 3.59375 39.511719 3.597656 39.503906 3.605469 L 4.300781 38.804688 C 4.179688 38.929688 4.089844 39.082031 4.042969 39.253906 L 2.035156 46.742188 C 1.941406 47.085938 2.039063 47.453125 2.292969 47.707031 C 2.484375 47.898438 2.738281 48 3 48 C 3.085938 48 3.171875 47.988281 3.257813 47.964844 L 10.746094 45.957031 C 10.917969 45.910156 11.070313 45.820313 11.195313 45.695313 L 46.394531 10.5 C 46.40625 10.488281 46.410156 10.472656 46.417969 10.460938 C 46.507813 10.371094 46.570313 10.308594 46.570313 10.308594 C 48.476563 8.40625 48.476563 5.324219 46.574219 3.425781 Z M 45.160156 4.839844 C 46.277344 5.957031 46.277344 7.777344 45.160156 8.894531 C 44.828125 9.222656 44.546875 9.507813 44.304688 9.75 L 40.25 5.695313 C 40.710938 5.234375 41.105469 4.839844 41.105469 4.839844 C 41.644531 4.296875 42.367188 4 43.132813 4 C 43.898438 4 44.617188 4.300781 45.160156 4.839844 Z M 5.605469 41.152344 L 8.847656 44.394531 L 4.414063 45.585938 Z"></path>
                            </svg>
                        </Tooltip>
                    </div>
                    <div
                        style={{
                            cursor: "pointer",

                        }}
                        onClick={() => {
                            Swal.fire({
                                title: i18n.t("confirm_popup_title"),
                                showCancelButton: true,
                                confirmButtonText: i18n.t("confirm_popup_confirm"),
                                cancelButtonText: i18n.t("confirm_popup_cancel"),
                                customClass: {
                                    title: 'delete-title',
                                    content: 'delete-title',
                                    cancelButton: 'delete-title',
                                    confirmButton: 'delete-title',
                                }
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    dispatch(deleteGodoActions.fetchDataTrigger(record.id));
                                }
                            });
                        }}
                    >
                        <Tooltip placement="top" title={"Delete"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="20"
                                height="20"
                                viewBox="0 0 30 30"
                            >
                                <path
                                    style={{fill: '#b60000'}}
                                    d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                            </svg>
                        </Tooltip>
                    </div>
                </div>
            ),
        },

    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const handlePaginationChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const data = useSelector(selectTasksData);
    const dispatch = useDispatch();


    useEffect(() => {
        const handleLanguageChange = () => {
            dispatch(tasksActions.fetchDataTrigger());
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [dispatch]);

    useEffect(() => {
        if (!data) {
            dispatch(tasksActions.fetchDataTrigger());
        }
    }, [dispatch, data, i18n.language]);


    const startIndex = (currentPage - 1) * pageSize;
    const renderStatus = (record) => {
        return record.actions?.data?.[0]?.status || "-";
    };

    if (data) {
        const displayData = Object.values(data.data).slice(
            startIndex,
            startIndex + pageSize
        );
        return (
            <>
                <Modal
                    visible={modalOpen}
                    onCancel={() => setModalOpen(false)}
                    footer=""
                    centered
                >
                    <div className="modall-wrapper">
                        <div className="modal-names">
                            <p>{i18n.t("dashboard_table_name")}</p>
                            <p>{i18n.t("dashboard_table_status")}</p>
                        </div>
                        <div className="modal-content">
                            <div className="modall-left">
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>
                                            {item.user.data.name && item.user.data.name.trim() !== ''
                                                ? item.user.data.name
                                                : item.user.data.nickname}
                                        </div>
                                    ))}
                            </div>
                            <div className="modall-right">
                                {modalData &&
                                    modalData.map((item, index) => (
                                        <div key={index}>{item.status}</div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Table
                    columns={columns}
                    rowKey={(task) => task.id}
                    dataSource={displayData}
                    pagination={false}
                />
                <CustomPagination
                    total={Object.values(data.data).length}
                    pageSize={pageSize}
                    current={currentPage}
                    onChange={handlePaginationChange}
                    onPageSizeChange={handlePageSizeChange}
                />
            </>
        );
    }

    return null;
};

export default TasksTable;
