import { Layout } from "antd";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import { Outlet } from "react-router-dom";
function DashboardLayout() {
  const { Content } = Layout;
  return (
    <>
      <Header />
      <Layout style={{ height: "calc(100vh - 48px" }}>
        <Sidebar />
        <Layout className="site-layout">
          <Content>
            <div className="site-layout-background">
            <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
export default DashboardLayout;
