import React, { useEffect } from 'react';
import httpClient from '../hooks/httpClient';
const withError = (WrappedComponent, $axiosInstance) => {
  return (props) => {
    const [error, clearError] = httpClient($axiosInstance);

    useEffect(() => {
      if (error) {
        clearError();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return <WrappedComponent {...props} />;
  };
};

export default withError;
