import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.login || initialState;


export const selectLoginLoading = createSelector(
  [selectDomain],
  (loginState) => loginState.fetch.loading
);

export const selectLoginDone = createSelector(
  [selectDomain],
  (loginState) => loginState.fetch.done
);

export const selectLoginError = createSelector(
  [selectDomain],
  (loginState) => loginState.fetch.error
);
