function DashboardTotalCard({ title, value, itemCollor }) {
  return (
    <>
      <div className="top-element" style={{backgroundColor:itemCollor}} ></div>
      <div className="dashboard-tottal-card-title">{title}</div>
      <div className="dashboard-tottal-card-value">{value}</div>
      <div className="bottom-element" style={{backgroundColor:itemCollor}} ></div>
    </>
  );
}
export default DashboardTotalCard;
