function Badly() {
    return (
        <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#FFCCC7"/>
            <path d="M6.25316 14.9261L10.3131 16.0962C10.4429 16.1336 10.4441 16.3158 10.3148 16.3516L6.02569 17.541"
                  stroke="#F68174" strokeWidth="1.06667" strokeLinecap="round"/>
            <path d="M25.8457 14.9261L21.7858 16.0962C21.656 16.1336 21.6548 16.3158 21.7841 16.3516L26.0732 17.541"
                  stroke="#F68174" strokeWidth="1.06667" strokeLinecap="round"/>
            <path
                d="M9.59985 21.9654C10.6665 21.0436 12.7999 19.2001 15.9999 19.2001C19.1999 19.2001 21.3332 21.0436 22.3999 21.9654"
                stroke="#F68174" strokeWidth="1.33333" strokeLinecap="round"/>
        </svg>
    );
}

function Normally() {
    return (
        <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#B0C5FE"/>
            <circle cx="10.1334" cy="13.3333" r="1.6" fill="#6484E2"/>
            <circle cx="21.8668" cy="13.3333" r="1.6" fill="#6484E2"/>
            <path d="M9.6001 20.2666C9.89267 20.2666 18.2553 20.2666 22.4001 20.2666" stroke="#6484E2"
                  strokeWidth="1.33333" strokeLinecap="round"/>
        </svg>

    );
}

function Fine() {
    return (
        <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#63C5E3"/>
            <path
                d="M11.2 13.4833C11.2 14.4498 10.2449 13.2838 9.06668 13.2838C7.88848 13.2838 6.93335 14.4498 6.93335 13.4833C6.93335 12.5168 7.88848 11.7333 9.06668 11.7333C10.2449 11.7333 11.2 12.5168 11.2 13.4833Z"
                fill="#44A6C4"/>
            <path
                d="M25.0667 13.4833C25.0667 14.4498 24.1116 13.2838 22.9334 13.2838C21.7552 13.2838 20.8 14.4498 20.8 13.4833C20.8 12.5168 21.7552 11.7333 22.9334 11.7333C24.1116 11.7333 25.0667 12.5168 25.0667 13.4833Z"
                fill="#44A6C4"/>
            <path
                d="M9.6001 19.1999C10.6668 20.1217 12.8001 21.9652 16.0001 21.9652C19.2001 21.9652 21.3334 20.1217 22.4001 19.1999"
                stroke="#44A6C4" strokeWidth="1.33333" strokeLinecap="round"/>
        </svg>

    );
}
function Satisfied() {
    return (
        <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#D4C3FF"/>
            <path
                d="M11.2 13.4833C11.2 14.4498 10.2449 13.2838 9.06668 13.2838C7.88848 13.2838 6.93335 14.4498 6.93335 13.4833C6.93335 12.5168 7.88848 11.7333 9.06668 11.7333C10.2449 11.7333 11.2 12.5168 11.2 13.4833Z"
                fill="#8E83F3"/>
            <path
                d="M25.0667 13.4833C25.0667 14.4498 24.1116 13.2838 22.9334 13.2838C21.7552 13.2838 20.8 14.4498 20.8 13.4833C20.8 12.5168 21.7552 11.7333 22.9334 11.7333C24.1116 11.7333 25.0667 12.5168 25.0667 13.4833Z"
                fill="#8E83F3"/>
            <path
                d="M15.8501 23.4666C12.7527 23.4666 10.3682 21.9492 8.94784 20.7242C8.28281 20.1507 8.71769 19.1999 9.59589 19.1999H22.2831C23.1829 19.1999 23.6097 20.1875 22.9098 20.7529C21.3998 21.9728 18.9233 23.4666 15.8501 23.4666Z"
                fill="#8E83F3"/>
        </svg>
    );
}


export {Badly, Normally, Fine, Satisfied};
