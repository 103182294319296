import { call, put } from "redux-saga/effects";
import { actions as localeActions } from "./slice";
import * as localeService from "./services";
function* fetchLocaleSaga() {
  try {
    const { data } = yield call(localeService.getLocale);
    yield put(localeActions.fetchDataSuccess(data));
  } catch (err) {
    yield put(localeActions.fetchDataFailed(err));
  } finally {
    yield put(localeActions.fetchDataFulfilled());
  }
}

export { fetchLocaleSaga };
