import { call, put } from "redux-saga/effects";
import { actions as resetPasswordActions } from "./slice";
import * as resetPasswordService from "./services";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
function* fetchresetPasswordSaga({ payload }) {
  try {
    const { data } = yield call(
      resetPasswordService.postResetPassword,
      payload
    );
    yield put(resetPasswordActions.fetchDataSuccess(data));
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "success",
      title: "Log in to your account ",
    });
  } catch (err) {
    yield put(resetPasswordActions.fetchDataFailed(err));
  } finally {
    yield put(resetPasswordActions.fetchDataFulfilled());
  }
}

export { fetchresetPasswordSaga };
