import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.tasks || initialState;

export const selectTasksData = createSelector(
  [selectDomain],
  (TasksState) => TasksState.data
);

export const selectTasksLoading = createSelector(
  [selectDomain],
  (TasksState) => TasksState.fetch.loading
);

export const selectTasksDone = createSelector(
  [selectDomain],
  (TasksState) => TasksState.fetch.done
);

export const selectTasksError = createSelector(
  [selectDomain],
  (TasksState) => TasksState.fetch.error
);
