import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.categories || initialState;

export const selectCategoriesData = createSelector(
  [selectDomain],
  (CategoriesState) => CategoriesState.data
);

export const selectCategoriesLoading = createSelector(
  [selectDomain],
  (CategoriesState) => CategoriesState.fetch.loading
);

export const selectCategoriesDone = createSelector(
  [selectDomain],
  (CategoriesState) => CategoriesState.fetch.done
);

export const selectCategoriesError = createSelector(
  [selectDomain],
  (CategoriesState) => CategoriesState.fetch.error
);
