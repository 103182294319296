function StatsItem({ title, ico, value, subTitle, percent }) {
  return (
    <div className="stats-item">
      <div className="stats-item-head">
        <div className="stats-item-title">{title}</div>
        <div className="stats-item-icon">{ico}</div>
      </div>
      <div className="stats-item-details">
        <div className="stats-item-value">{value}</div>
        <svg
          width="100%"
          height="2"
          viewBox="0 0 230 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 1H230" stroke="black" strokeOpacity="0.06" />
        </svg>
      </div>
      <div className="stats-item-bottom">
        <div className="stats-item-subtitle">{subTitle}</div>
        <div className="stats-item-percent">{percent}</div>
      </div>
    </div>
  );
}
export default StatsItem;
