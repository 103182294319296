import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.resetPassword || initialState;
export const selectresetPasswordData = createSelector(
  [selectDomain],
  (resetPasswordState) => resetPasswordState.data
);

export const selectresetPasswordLoading = createSelector(
  [selectDomain],
  (resetPasswordState) => resetPasswordState.fetch.loading
);

export const selectresetPasswordDone = createSelector(
  [selectDomain],
  (resetPasswordState) => resetPasswordState.fetch.done
);

export const selectresetPasswordError = createSelector(
  [selectDomain],
  (resetPasswordState) => resetPasswordState.fetch.error
);
