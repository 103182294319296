import React, {useEffect, useState} from "react";
import {ConfigProvider, DatePicker, Input, Select} from "antd";
import {Controller, Form, useForm} from "react-hook-form";
import {format, parse} from "date-fns";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {actions as newGodoActions} from "../../../store/modules/newGodo/slice";
import i18n from "i18next";
import {selectCategoriesData} from "../../../store/modules/categories/selector";
import {actions as categoriesActions} from "../../../store/modules/categories/slice";
import {selectTasksData} from "../../../store/modules/allTasks/selector";
import {actions as tasksActions} from "../../../store/modules/allTasks/slice";


function NewGodoForms() {
    const dispatch = useDispatch();
    const categories = useSelector(selectCategoriesData);
    useEffect(() => {
        if (!categories) dispatch(categoriesActions.fetchDataTrigger());
    }, [categories]);
    const navigate = useNavigate();
    const convertToMinutes = (duration) => {
        console.log(duration)
        if (duration) {
            if (duration.includes(":")) {
                const [hours, minutes] = duration.split(":").map(Number);
                return hours * 60 + minutes;
            }
            return Number(duration) * 60;
        }
    };
    const formatDate = (dateString) => {
        const parsedDate = parse(dateString, "dd/MM/yyyy hh:mm a", new Date());
        return format(parsedDate, "yyyy-MM-dd HH:mm");
    };
    const [fileList, setFileList] = useState([]);
    const [fileKey, setFileKey] = useState(0);
    const {data, setValue} = useForm();
    const handleRemove = (fileToRemoveKey) => {
        const indexToRemove = fileList.findIndex((file) => file.uid === fileToRemoveKey);
        if (indexToRemove !== -1) {
            const newFileList = [...fileList];
            newFileList.splice(indexToRemove, 1);
            setFileList(newFileList);
        }
    };

    const handleFileSelect = (e) => {
        const files = e.target.files;
        const newFileList = Array.from(files).map((file) => ({
            uid: fileKey, file: file, name: file.name,
        }));

        setFileList([...fileList, ...newFileList]);
        setFileKey(fileKey + 1);
    };
    const {
        control, handleSubmit, reset, formState: {errors},
    } = useForm({});

    const onSubmit = (data, e) => {
        const formData = new FormData();

        // Append only defined values to formData
        Object.entries(data).forEach(([key, value]) => {
            if (key !== "images" && value !== undefined ) {
                formData.append(key, value);
            }
        });

        const unformattedDate = new Date(data.date);

        const year = unformattedDate.getFullYear();
        const month = unformattedDate.getMonth() + 1;
        const day = unformattedDate.getDate();
        const hours = unformattedDate.getHours();
        const minutes = unformattedDate.getMinutes();
        const durationInMinutes = convertToMinutes(data.duration);

        // const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        fileList.forEach((file) => {
            formData.append("images[]", file.file);
        });
        if (durationInMinutes !== undefined){
            if(durationInMinutes !== null)
            formData.append("duration", durationInMinutes);
            else
                formData.append("duration", 0);

        }
        if (!isNaN(year) && !isNaN(month) && !isNaN(day) && !isNaN(hours) && !isNaN(minutes) && year !== 1970) {
            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            formData.append("date", formattedDate);
        }

        // formData.append("duration", durationInMinutes);

        // Dispatch the formData
        dispatch(newGodoActions.fetchDataTrigger(formData));

        // Reset form and other states
        reset();
        setFileList([]);
        setValue("type", "");
        setValue("data", null);

        dispatch(tasksActions.fetchDataTrigger());

        setTimeout(() => {
            navigate("/tasks");
            dispatch(tasksActions.fetchDataTrigger());
        }, 900);
    };

    if (categories) {
        return (<Form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className="new-godo-form"
            id="new-godo-form"
            control={control}
        >
            <Controller
                name="title"
                control={control}
                rules={{
                    required: i18n.t("error_required"),
                    minLength: {
                        value: 3,
                        message: i18n.t("dashboard_new_godo_min_len"),
                    },
                }}
                render={({field}) => (<div className="input-wrapp">
                    <div className="inp">
                        <div className="wrapp">
                <span className="input-title">
                  {i18n.t("dashboard_new_godo_task_name")}
                </span>
                            <Input
                                type="text"
                                name="title"
                                placeholder={i18n.t("dashboard_new_godo_task_name_placeholder")}
                                {...field}
                                style={{
                                    border: errors.title ? " 1px solid #E33C22" : "1px solid #D9D9D9",
                                }}
                            />
                        </div>
                    </div>
                    {errors.title &&
                        <span className={"new_godo_error"} style={{color: "#E33C22"}}>{errors.title.message}</span>}
                </div>)}
            />
            <Controller
                name="description"
                control={control}
                rules={{
                    // required: i18n.t("error_required"),
                    minLength: {
                        value: 3,
                        message: i18n.t("dashboard_new_godo_min_len"),
                    },
                }}
                render={({field}) => (<div className="input-wrapp">
                        <div className="inp">
            <span className="input-title">
              {i18n.t("dashboard_table_one_godo_desc")}
            </span>
                            <Input
                                type="text"
                                name="description"
                                placeholder={i18n.t("dashboard_new_godo_task_desc_placeholder")}
                                style={{
                                    border: errors.description ? " 1px solid #E33C22" : "1px solid #D9D9D9",
                                }}
                                {...field}
                            />
                        </div>
                        {errors.description && <span className={"new_godo_error"}
                                                     style={{color: "#E33C22"}}>{errors.description.message}</span>}
                    </div>
                )}
            />
            <Controller
                name="date"
                rules={{
                    // required: i18n.t("error_required"),

                }}
                control={control}
                render={({field}) => (<div className="input-wrapp">
                    <div className="inp">
                <span className="input-title">
              {i18n.t("dashboard_table_one_godo_data")}
            </span>

                        <ConfigProvider
                            theme={{
                                components: {
                                    DatePicker: {},
                                },
                            }}
                        >
                            <fieldset>
                                <DatePicker
                                    placement={"bottomLeft"}
                                    format="DD/MM/YYYY hh:mm A"
                                    placeholder={i18n.t("dashboard_new_godo_task_data_placeholder")}
                                    onChange={(date, dateString) => {
                                        const formattedDate = dateString ? formatDate(dateString) : "";
                                        field.onChange(formattedDate);
                                    }}
                                    showTime={{format: "h:mm:ss"}}
                                    style={{
                                        border: errors.date ? " 1px solid #E33C22" : "1px solid #D9D9D9",
                                    }}
                                    {...field}
                                />
                            </fieldset>
                        </ConfigProvider>
                    </div>
                    {errors.date &&
                        <span className={"new_godo_error"} style={{color: "#E33C22"}}>{errors.date.message}</span>}
                </div>)}
            />
            <Controller
                name="duration"
                defaultValue={0}
                rules={{
                    // required: i18n.t("error_required"),
                }}
                control={control}
                render={({field}) => (<div className="input-wrapp">
                    <div className="inp">

            <span className="input-title">
              {i18n.t("dashboard_table_one_godo_duraction")}
            </span>
                        <Input
                            style={{
                                border: errors.duration ? " 1px solid #E33C22" : "1px solid #D9D9D9",
                                width: 484
                            }}

                            placeholder={i18n.t("dashboard_table_one_godo_duraction_placeholde")}
                            suffix={<svg
                                width="14"
                                height="24"
                                viewBox="0 0 14 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_696_4053)">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M3.11501 17.8233C4.26615 18.5925 5.61953 19.003 7.004 19.003C8.86052 19.003 10.641 18.2655 11.9537 16.9528C13.2665 15.64 14.004 13.8596 14.004 12.003C14.004 10.6186 13.5935 9.26519 12.8243 8.11404C12.0551 6.9629 10.9619 6.06569 9.68279 5.53588C8.4037 5.00607 6.99624 4.86744 5.63837 5.13754C4.2805 5.40764 3.03322 6.07432 2.05425 7.05329C1.07529 8.03226 0.408601 9.27954 0.138504 10.6374C-0.131593 11.9953 0.0070308 13.4027 0.536844 14.6818C1.06666 15.9609 1.96387 17.0542 3.11501 17.8233ZM3.67058 7.01422C4.65727 6.35493 5.81731 6.00304 7.004 6.00304C8.5953 6.00304 10.1214 6.63518 11.2466 7.7604C12.3719 8.88561 13.004 10.4117 13.004 12.003C13.004 13.1897 12.6521 14.3498 11.9928 15.3365C11.3335 16.3232 10.3965 17.0922 9.3001 17.5463C8.20374 18.0004 6.99735 18.1193 5.83346 17.8877C4.66957 17.6562 3.60048 17.0848 2.76136 16.2457C1.92225 15.4066 1.3508 14.3375 1.11929 13.1736C0.887778 12.0097 1.0066 10.8033 1.46072 9.70694C1.91485 8.61058 2.68388 7.67351 3.67058 7.01422ZM6.504 12.208L9.299 15.003L10.004 14.298L7.504 11.793V7.50304H6.504V12.208Z"
                                        fill="black"
                                        fillOpacity="0.25"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_696_4053">
                                        <rect
                                            width="14"
                                            height="24"
                                            fill="white"
                                            transform="translate(0.00201416)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>}
                            onKeyPress={(e) => {
                                const charCode = e.which ? e.which : e.keyCode;
                                const key = e.key;
                                if (!/[0-9:]/.test(key)) {
                                    e.preventDefault();
                                }
                            }}
                            {...field}
                        />
                    </div>
                    {errors.duration &&
                        <span className={"new_godo_error"} style={{color: "#E33C22"}}>{errors.duration.message}</span>}
                </div>)}
            />
            <Controller
                name="type"
                rules={{
                    required: i18n.t("error_required"),

                }}
                defaultValue=""
                control={control}
                render={({field}) => (<div className="input-wrapp">
                        <div className="inp">
            <span className="input-title">
              {i18n.t("dashboard_new_godo_task_category")}
            </span>
                            <Select
                                options={categories && categories.data.map(category => ({
                                    value: category.slug,
                                    label: category.title,
                                }))}
                                onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                }}
                                {...field}
                                style={{
                                    border: errors.type ? " 1px solid #E33C22" : "none",
                                    width: 484,
                                }}
                            />
                        </div>
                        {errors.type &&
                            <span className={"new_godo_error"} style={{color: "#E33C22"}}>{errors.type.message}</span>}
                    </div>
                )}
            />
            <Controller
                name="images"
                control={control}
                render={({field}) => (<div className="input-wrapp" style={{gap: 12}}>
                    <div className="inp" style={{gap: 12}}>

            <span className="input-title" style={{marginRight: -10}}>
              {i18n.t("dashboard_new_godo_task_add_photo")}
            </span>
                        <div className="photo-list">
                            {fileList.map((file) => (<div key={file.uid} className="photo-item">
                                <span className="photo-text">{file.file.name}</span>
                                <svg
                                    className="delete-icon"
                                    onClick={() => handleRemove(file.uid)}
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4.57594 3.99971L7.50563 0.507518C7.55474 0.449483 7.51344 0.361313 7.43755 0.361313H6.54693C6.49447 0.361313 6.44425 0.38475 6.40965 0.424929L3.99335 3.30551L1.57706 0.424929C1.54358 0.38475 1.49335 0.361313 1.43978 0.361313H0.549157C0.473264 0.361313 0.431969 0.449483 0.481076 0.507518L3.41076 3.99971L0.481076 7.49189C0.470076 7.50483 0.463019 7.52065 0.460742 7.53748C0.458466 7.55431 0.461066 7.57143 0.468234 7.58683C0.475402 7.60222 0.486837 7.61523 0.501181 7.62432C0.515525 7.63341 0.532176 7.63819 0.549157 7.6381H1.43978C1.49224 7.6381 1.54246 7.61466 1.57706 7.57448L3.99335 4.6939L6.40965 7.57448C6.44313 7.61466 6.49335 7.6381 6.54693 7.6381H7.43755C7.51344 7.6381 7.55474 7.54993 7.50563 7.49189L4.57594 3.99971Z"
                                        fill="black"
                                        fillOpacity="0.45"
                                    />
                                </svg>
                            </div>))}
                        </div>
                        <Input
                            type="file"
                            style={{display: "none"}}
                            multiple
                            accept = ".jpg, .jpeg, .png ,.webp"
                            onChange={handleFileSelect}
                        />

                        <svg className={"add-svg"} style={{cursor: "pointer"}}
                             onClick={() => document.querySelector('input[type="file"]').click()} width="32" height="34"
                             viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_2009_731)">
                                <rect x="-0.00195312" width="32" height="32" rx="16" fill="white"/>
                                <rect x="0.498047" y="0.5" width="31" height="31" rx="15.5" stroke="#D9D9D9"/>
                                <path
                                    d="M16.2715 11.9287L12.9766 21H11.6299L15.4238 11.0469H16.292L16.2715 11.9287ZM19.0332 21L15.7314 11.9287L15.7109 11.0469H16.5791L20.3867 21H19.0332ZM18.8623 17.3154V18.3955H13.2705V17.3154H18.8623Z"
                                    fill="black" fillOpacity="0.85"/>
                                <rect width="14" height="14" transform="translate(8.99805 9.00464)" fill="white"/>
                                <path
                                    d="M16.4667 10.3804H15.5292C15.4458 10.3804 15.4042 10.422 15.4042 10.5054V15.4116H10.7483C10.665 15.4116 10.6233 15.4533 10.6233 15.5366V16.4741C10.6233 16.5575 10.665 16.5991 10.7483 16.5991H15.4042V21.5054C15.4042 21.5887 15.4458 21.6304 15.5292 21.6304H16.4667C16.55 21.6304 16.5917 21.5887 16.5917 21.5054V16.5991H21.2483C21.3316 16.5991 21.3733 16.5575 21.3733 16.4741V15.5366C21.3733 15.4533 21.3316 15.4116 21.2483 15.4116H16.5917V10.5054C16.5917 10.422 16.55 10.3804 16.4667 10.3804Z"
                                    fill="black" fillOpacity="0.85"/>
                            </g>
                        </svg>
                    </div>
                </div>)}
            />
            <div className="button-wr">
                <div className="wr-wr">
                    <button type="submit" className="create-task">
                        {i18n.t("dashboard_new_godo_task_add_task")}
                    </button>
                </div>
            </div>
        </Form>);
    } else {
        return null;
    }
}

export default NewGodoForms;
