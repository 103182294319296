import { call, put } from "redux-saga/effects";
import { actions as restorePasswordActions } from "./slice";
import * as restorePasswordService from "./services";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18next";

function* fetchrestorePasswordSaga({ payload }) {
  try {
    const { data } = yield call(
        restorePasswordService.postRestorePassword,
        payload
    );
    yield put(restorePasswordActions.fetchDataSuccess(data));

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    Toast.fire({
      icon: "success",
      title: i18n.t("popup_restore_succes_title"),
    });

  } catch (err) {
    if (err.response && err.response.status === 422) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });

      Toast.fire({
        icon: "error",
        title: i18n.t("popup_restore_error_title"),
      });
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });

      Toast.fire({
        icon: "error",
        title: "Server error",
      });
      yield put(restorePasswordActions.fetchDataFailed(err));
    }
  } finally {
    yield put(restorePasswordActions.fetchDataFulfilled());
  }
}

export { fetchrestorePasswordSaga };
