import { call, put } from "redux-saga/effects";
import { actions as loginActions } from "./slice";
import * as loginService from "./services";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setAuthAccessToken } from "../app/services";
import { actions as appActions } from "../app/slice";
import i18n from "i18next";

const MySwal = withReactContent(Swal);

function* fetchLoginSaga({ payload }) {
  try {
    const { data } = yield call(loginService.postLogin, payload);
    yield put(loginActions.fetchDataSuccess(data));
    yield put(appActions.autoLoginSetToken({ token: data.access_token }));
    yield call(setAuthAccessToken, data.access_token);

    MySwal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
      icon: "success",
      title: i18n.t("popup_login_title"),
    });
  } catch (err) {
    if (err.response?.status === 422) {
      const errors = err.response.data.errors;

      let errorMessage = i18n.t("popup_login_error_generic");

      if (errors && errors.length > 0) {
        errorMessage = errors.map((error) => error.message).join("\n");
      }

      yield put(loginActions.fetchDataFailed(err));
      MySwal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
        icon: "error",
        title: i18n.t("popup_login_error_title"),
      });
    } else {
      yield put(loginActions.fetchDataFailed(err));
      MySwal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
        icon: "error",
        title: i18n.t("popup_login_error_title"),
      });
    }
  } finally {
    yield put(loginActions.fetchDataFulfilled());
  }
}

export { fetchLoginSaga };
