import { initialState } from "./state";
import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "tasks",
  initialState: initialState,
  reducers: {
    // fetch
    fetchDataTrigger(state, _action) {
      state.fetch.loading = true;
      state.fetch.done = false;
      state.fetch.error = null;
    },
    fetchDataSuccess(state, action) {
      state.fetch.done = true;
      state.data = action.payload;
    },
    fetchDataFailed(state, action) {
      state.fetch.error = action.payload;
    },
    fetchDataFulfilled(state) {
      state.fetch.loading = false;
      state.fetch.done = false;
    },
  },
});

const { actions, reducer, name } = appSlice;

export { actions, reducer, name };
