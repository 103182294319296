import "./style.css";
import React, {useEffect, useState} from "react";
import {GlobalOutlined, LogoutOutlined, MenuOutlined} from "@ant-design/icons";
import {ConfigProvider, Dropdown, Modal, Tooltip} from "antd";
import {actions as userActions} from "../../store/modules/user/slice";
import {useDispatch, useSelector} from "react-redux";
import {selectUserData} from "../../store/modules/user/selector";
import i18n from "i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {actions as categoriesActions} from "../../store/modules/categories/slice";

function Header() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const handleClearLocalStorage = () => {
        localStorage.clear();
        navigate("/")
        window.location.reload();
    };
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleLanguageChange = (newLocale) => {
        localStorage.setItem("locale", newLocale);
        i18n.changeLanguage(newLocale);
    };
    const data = useSelector(selectUserData);

    const [selectedLanguage, setSelectedLanguage] = useState();

    const handleLanguageClick = (lang) => {
        if (lang !== selectedLanguage) {
            setSelectedLanguage(lang);
            handleLanguageChange(lang);
            dispatch(categoriesActions.fetchDataTrigger());
        }
    };
    const dispatch = useDispatch();
    useEffect(() => {
        if (!data) {
            dispatch(userActions.fetchDataTrigger());
        }
    }, [data, dispatch]);
    const items = [
        {
            key: "1",
            label: "EN",
            onClick: () => handleLanguageClick("en"),
        },
        {
            key: "2",
            label: "SV",
            onClick: () => handleLanguageClick("sv"),
        },
        {
            key: "3",
            label: "FIN",
            onClick: () => handleLanguageClick("fi"),
        },
        {
            key: "4",
            label: "UA",
            onClick: () => handleLanguageClick("ua"),
        },
    ];
    if (data) {
        return (
            <header>
                <a className="header-logo" onClick={() => {
                    if (location.pathname !== '/') {
                        navigate("/");
                    }
                }}
                >
                    <svg
                        width="91"
                        height="24"
                        viewBox="0 0 91 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.9388 14.5094V12.4296L24.2439 10.3499C24.2439 13.988 24.171 17.5649 21.5128 20.4459C20.8993 21.0903 20.2065 21.6761 19.3952 22.1643C18.5837 22.6526 17.8513 23.0042 17.1982 23.2189C16.5451 23.4337 15.8524 23.6095 15.1004 23.7463C14.3285 23.8829 13.814 23.9611 13.5171 23.9805C13.2203 24 12.9037 24 12.5672 24C9.08413 24 6.11544 22.8283 3.6811 20.4849C1.22703 18.1611 0 15.3295 0 11.9902C0 8.65095 1.2073 5.81937 3.64152 3.49547C6.05602 1.17168 9.0246 6.16381e-07 12.5672 6.16381e-07C13.913 6.16381e-07 15.1995 0.156211 16.4067 0.488211C17.6139 0.820211 18.5837 1.21074 19.3555 1.65979C20.1076 2.10905 20.82 2.61674 21.4731 3.22211C22.1263 3.82747 22.6012 4.27663 22.8586 4.58905C23.1158 4.92105 23.3335 5.23347 23.5117 5.5459L19.9492 7.98705C18.3066 5.5459 15.8327 4.31579 12.5672 4.31579C10.4298 4.31579 8.62882 5.0579 7.18416 6.52253C5.71955 8.00663 4.98729 9.82274 4.98729 11.9903C4.98729 14.0994 5.71955 15.896 7.20389 17.3996C8.68824 18.9033 10.4694 19.6648 12.5672 19.6648C14.9421 19.6648 16.8421 18.8447 17.6733 17.8293C18.5045 16.8138 19.0389 15.7593 19.1379 15.0758L13.9388 14.5094Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M36.1951 16.1408C38.7746 16.1408 40.6341 14.1621 40.6341 12C40.6341 9.83786 38.7746 7.85916 36.1951 7.85916C33.6157 7.85916 31.7561 9.83786 31.7561 12C31.7561 14.1621 33.6157 16.1408 36.1951 16.1408ZM36.1951 20.8732C41.2869 20.8732 45.4146 16.9006 45.4146 12C45.4146 7.09945 41.2869 3.12676 36.1951 3.12676C31.1033 3.12676 26.9756 7.09945 26.9756 12C26.9756 16.9006 31.1033 20.8732 36.1951 20.8732Z"
                            fill="white"
                        />
                        <path
                            d="M57.6441 24H48.1463V0H57.6441C61.2341 0 64.147 1.13334 66.424 3.41997C68.7011 5.7066 69.8293 8.5598 69.8293 12.0202C69.8293 15.4806 68.7011 18.3339 66.424 20.6004C64.147 22.8667 61.234 24 57.6441 24ZM57.6441 4.47215H53.2337V19.5278H57.6441C59.7982 19.5278 61.5007 18.8398 62.7521 17.4436C64.0034 16.0674 64.6188 14.2664 64.6188 12.0202C64.6188 9.77407 64.0034 7.95277 62.7521 6.55644C61.5007 5.16022 59.798 4.47215 57.6441 4.47215Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M81.7805 16.1408C84.3599 16.1408 86.2195 14.1621 86.2195 12C86.2195 9.83786 84.3599 7.85916 81.7805 7.85916C79.201 7.85916 77.3415 9.83786 77.3415 12C77.3415 14.1621 79.201 16.1408 81.7805 16.1408ZM81.7805 20.8732C86.8723 20.8732 91 16.9006 91 12C91 7.09945 86.8723 3.12676 81.7805 3.12676C76.6887 3.12676 72.561 7.09945 72.561 12C72.561 16.9006 76.6887 20.8732 81.7805 20.8732Z"
                            fill="white"
                        />
                    </svg>
                </a>
                <div className="header-toolbar" style={{paddingRight: 12}}>
                    <div className="header-toolbar-left">
                        {data.data.image ? <img src={data.data.image} alt="user-ico" className="user-ico"/> :
                            <img src="/avatar.png" alt="user-ico" className="user-ico"/>}
                        {/*<img src={data.data.image} alt="user-ico" className="user-ico"/>*/}
                        <p className="user-name">{data.data.title}</p>
                    </div>
                    <div className="header-toolbar-right">
                        <ConfigProvider
                            theme={{
                                components: {
                                    Dropdown: {},
                                },
                                colorBgElevated: "#000000",
                            }}
                        >
                            <fieldset>
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottom"
                                >
                                    <GlobalOutlined/>
                                </Dropdown>
                            </fieldset>
                        </ConfigProvider>
                    </div>
                    <div className="mobile-menu" onClick={showModal}>
                        <MenuOutlined style={{color: "white", cursor: "pointer"}}/>
                    </div>

                    <Modal
                        className={"mobile-modal"}
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={null}
                        width="100%"
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%"
                        }}>
                            <a onClick={() => {
                                navigate("/");
                                setIsModalVisible(false);
                            }}>{i18n.t("dashboard_menu_main")}</a>
                            <a onClick={() => {
                                navigate("/tasks");
                                setIsModalVisible(false);
                            }}>{i18n.t("dashboard_menu_all_tasks")}</a>
                            <a onClick={() => {
                                navigate("/new-godo");
                                setIsModalVisible(false);
                            }}>{i18n.t("dashboard_menu_new_godo")}</a>
                            <a onClick={handleClearLocalStorage}>
                                {i18n.t("dashboard_menu_logout")}
                                <LogoutOutlined
                                    style={{color: "black", marginLeft: "5px", cursor: "pointer"}}/></a>

                        </div>
                    </Modal>
                    <Tooltip placement="bottom" className={"logout-ico"} title={"Logout"}>
                        <LogoutOutlined onClick={handleClearLocalStorage} style={{color: "white", cursor: "pointer"}}/>
                    </Tooltip>
                    <Tooltip placement="bottom"  title={"Reload"}>
                        <svg onClick={() => window.location.reload()} style={{fill: "white", cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" x="0px"
                             y="0px" width="18" height="18"
                             viewBox="0 0 30 30">
                            <path
                                d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
                        </svg>
                    </Tooltip>
                </div>
            </header>
        );

    }
}

export default Header;
