import NewGodoForms from "../../components/forms/newGodoForms";
import "./style.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
function NewGodo() {
    const {t} = useTranslation();

  return (
    <div className="new-godo-wrapper">
      <div className="new-godo-head">
        <div className="new-godo-title">{i18n.t("dashboard_menu_new_godo")}</div>
        <div className="new-godo-sub">{i18n.t("dashboard_new_godo_sub")}</div>
      </div>
      <div className="new-godo-forms-wrapper">
        <NewGodoForms />
      </div>
    </div>
  );
}
export default NewGodo;
