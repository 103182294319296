import { reducer as app } from "./modules/app/slice";
import { reducer as user } from "./modules/user/slice";
import { reducer as login } from "./modules/login/slice";
import { reducer as dashboard } from "./modules/dashboard/slice";
import { reducer as tasks } from "./modules/allTasks/slice";
import { reducer as resetPassword } from "./modules/resetPassword/slice";
import { reducer as newGodo } from "./modules/newGodo/slice";
import { reducer as oneGodo } from "./modules/oneGodo/slice";
import { reducer as locale } from "./modules/oneGodo/slice";
import { reducer as refresh } from "./modules/refreshToken/slice";
import { reducer as categories } from "./modules/categories/slice";
import { reducer as editGodo } from "./modules/editGodo/slice";
import { reducer as deleteGodo } from "./modules/deleteGodo/slice";
import { reducer as restorePassword } from "./modules/restorePassword/slice";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  app,
  user,
  login,
  dashboard,
  tasks,
  resetPassword,
  newGodo,
  oneGodo,
  locale,
  refresh,
  categories,
  editGodo,
  deleteGodo,
  restorePassword
};
