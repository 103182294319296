import "./style.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import EditGodoForms from "../../components/editGodo";
function EditGodo() {
    const {t} = useTranslation();

    return (
        <div className="new-godo-wrapper">
            <div className="new-godo-head">
                <div className="new-godo-title">{i18n.t("dashboard_menu_edit_godo")}</div>
                <div className="new-godo-sub">{i18n.t("dashboard_edit_godo_sub")}</div>
            </div>
            <div className="new-godo-forms-wrapper">
                <EditGodoForms />
            </div>
        </div>
    );
}
export default EditGodo;
