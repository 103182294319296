import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.app || initialState;

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  (appState) => appState.token
);

export const selectError = createSelector(
  [selectDomain],
  (appState) => appState.error
);
