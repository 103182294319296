import DashboardTotalCard from "./item";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as dashboardActions } from "../../../store/modules/dashboard/slice";
import { selectDashboardData } from "../../../store/modules/dashboard/selector";
import "./style.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
function DashboardTotal() {
  const { t } = useTranslation();
  function minutesToHours(minutes) {
    if (minutes === 0) {
      return 0;
    }
    const hours = minutes;
    return hours;
  }
  const data = useSelector(selectDashboardData);
  let totalData = [];
  if (data && data.time_spent_by_categories) {
    const predefinedColors = [
      "#6BE095",
      "#503CCF",
      "#FBBC05",
      "#D4C3FF",
      "#B0C5FE",
    ];

    const itemColors = ["#5BD486", "#402DB8", "#EFB100", "#BCA6F3", "#92ADF5"];

    const categories = Object.entries(data.time_spent_by_categories);
    totalData = categories.map(([key, value], index) => ({
      title: `${value.time} ` + i18n.t("dashboard_hours"),
      value: `${value.title}`,
      bgCollor: `${value.color}`,
      itemsCollor:`${value.color}`,
    }));
  }
  if (data) {
    return (
      <div className="dashboard-total">
        <div className="dashboard-total-head">
          <div className="title"> {i18n.t("dashboard_total")}</div>
          <div className="time">{minutesToHours(data.time_spent_minutes)}</div>
        </div>
        <div className="dashboard-cards">
          {totalData && totalData.length && totalData.map((item, index) => (
            <div
              key={index}
              className="dashboard-cards-item"
              style={{ backgroundColor: item.bgCollor }}
            >
              <DashboardTotalCard
                title={item.title}
                value={item.value}
                itemCollor={item.itemsCollor}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default DashboardTotal;
