import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-http-backend";
import axios from "axios";
import translationUK from "../locales/uk/translition.json";
import translationEN from "../locales/en/translition.json";
import translationFI from "../locales/fin/translition.json";
import translationSV from "../locales/sv/translition.json";
import ApiConfig from "../config/api-config";
import store from "../../src/store";
import {actions as localeActions} from "../../src/store/modules/locale/slice";

const axiosInstance = axios.create({
    baseURL: ApiConfig.BASE_URL,
});
export const resources = {
    EN: {
        translation: translationEN,
    },
    UA: {
        translation: translationUK,
    },
    FI: {
        translation: translationFI,
    },
    SV: {
        translation: translationSV,
    },
};

i18n.use(Backend).init({
    interpolation: {
        escapeValue: false,
    },
    compatibilityJSON: "v3",
    lng: localStorage.getItem("locale") || "en",
    react: {
        useSuspense: false,
    },
    backend: {
        loadPath: () => {
            return `${ApiConfig.LOCALE}`;
        },
        request: async (_options, url, _payload, callback) => {
            let locale = (await localStorage.getItem("locale")) || "en";
            i18n.changeLanguage(locale);
            let config = {
                headers: {
                    "X-localization": locale,
                },
            };
            await axiosInstance
                .get(url, config)
                .then((res) => {
                    setTimeout(() => {
                        store.dispatch(localeActions.fetchDataTrigger(true));
                    }, 500);
                    callback(null, res);

                })
                .catch((err) => {
                    i18n.use(initReactI18next).init({
                        compatibilityJSON: "v3",
                        resources,
                        lng: "en",
                        fallbackLng: "sv",
                    });
                    setTimeout(() => {
                        store.dispatch(localeActions.fetchDataTrigger(true));
                    }, 500);
                    // @ts-ignore
                    callback(err, null);
                });
        },
    },
});
