import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as resetPasswordActions } from "../../../store/modules/resetPassword/slice";
import { useEffect } from "react";
import { selectresetPasswordData } from "../../../store/modules/resetPassword/selector";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const ResetForm = ({ email, token }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetDone = useSelector(selectresetPasswordData);
  const { t } = useTranslation();

  const password = watch("password");

  useEffect(() => {
    if (resetDone) {
      reset();
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDone]);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      email: email,
      token: token,
    };
    dispatch(resetPasswordActions.fetchDataTrigger(payload));
  };

  return (
      <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="login-form"
          id="restore-form"
      >
        <Controller
            name="password"
            control={control}
            rules={{
              required: i18n.t("error_required"),
                minLength: {
                    value: 6,
                    message: i18n.t("dashboard_reset_min_len"),
                },
            }}
            render={({ field }) => (
                <>
                  <Input.Password
                      placeholder={i18n.t("login_password")}
                      name="password"
                      {...field}
                      iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      style={{
                        border: errors.password  ?
                            " 1px solid #E33C22" : "1px solid #D9D9D9",
                      }}
                  />
                  {errors.password && (
                      <span className="error-message">{errors.password.message}</span>
                  )}
                </>
            )}
        />

        <Controller
            name="password_confirmation"
            control={control}
            rules={{
              required: i18n.t("error_required"),
                minLength: {
                    value: 6,
                    message: i18n.t("dashboard_reset_min_len"),
                },
              validate: (value) =>
                  value === password || i18n.t("error_password_match"),
            }}
            render={({ field }) => (
                <>
                  <Input.Password
                      placeholder={i18n.t("dashboard_confirm_pass")}
                      name="password_confirmation"
                      {...field}
                      iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      style={{
                        border: errors.password_confirmation ?
                          " 1px solid #E33C22" : "1px solid #D9D9D9",
                      }}
                  />
                  {errors.password_confirmation && (
                      <span className="error-message">
                {errors.password_confirmation.message}
              </span>
                  )}
                </>
            )}
        />
        <button className="submitbtn" type="submit">
          {i18n.t("dashboard_confirm")}
        </button>
      </form>
  );
};

export default ResetForm;
