import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.restorePassword || initialState;
export const selectrestorePasswordData = createSelector(
  [selectDomain],
  (restorePasswordState) => restorePasswordState.data
);

export const selectrestorePasswordLoading = createSelector(
  [selectDomain],
  (restorePasswordState) => restorePasswordState.fetch.loading
);

export const selectrestorePasswordDone = createSelector(
  [selectDomain],
  (restorePasswordState) => restorePasswordState.fetch.done
);

export const selectrestorePasswordError = createSelector(
  [selectDomain],
  (restorePasswordState) => restorePasswordState.fetch.error
);
