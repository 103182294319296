import "./style.css";

function Loader() {
    return (
        <div className={"loader"}>
            <div className={"loader-wrapper"}>
                <svg width="380" height="102" viewBox="0 0 380 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M58.2061 61.7733V52.9903L101.238 44.2078C101.238 59.5715 100.934 74.677 89.8336 86.8433C87.2717 89.5647 84.3787 92.0385 80.9909 94.1002C77.6021 96.1624 74.5439 97.6471 71.8167 98.554C69.0895 99.4608 66.197 100.203 63.0564 100.781C59.8334 101.358 57.6849 101.688 56.4451 101.77C55.2058 101.852 53.8836 101.852 52.4786 101.852C37.9337 101.852 25.537 96.9043 15.3716 87.0082C5.12388 77.1944 0 65.2366 0 51.1348C0 37.0331 5.04147 25.0753 15.2064 15.2614C25.2889 5.44804 37.6852 0.500003 52.4786 0.500003C58.0983 0.500003 63.4703 1.15968 68.5113 2.56172C73.5527 3.96376 77.6021 5.61297 80.8252 7.50932C83.9658 9.40657 86.9407 11.5505 89.6679 14.107C92.3955 16.6635 94.3788 18.5603 95.4533 19.8796C96.5274 21.2817 97.4366 22.601 98.1805 23.9204L83.3043 34.2294C76.4452 23.9204 66.1146 18.7256 52.4786 18.7256C43.553 18.7256 36.0324 21.8596 29.9998 28.0447C23.8838 34.3121 20.8261 41.9816 20.8261 51.1353C20.8261 60.0418 23.8838 67.6291 30.0822 73.9787C36.2806 80.3288 43.7183 83.5449 52.4786 83.5449C62.3958 83.5449 70.3297 80.0816 73.8004 75.7933C77.2716 71.5049 79.503 67.0516 79.9164 64.1653L58.2061 61.7733Z"
                        fill="white" className="svg-elem-1"></path>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M151.144 68.663C161.916 68.663 169.681 60.3069 169.681 51.1762C169.681 42.0454 161.916 33.6893 151.144 33.6893C140.373 33.6893 132.608 42.0454 132.608 51.1762C132.608 60.3069 140.373 68.663 151.144 68.663ZM151.144 88.648C172.407 88.648 189.644 71.8713 189.644 51.1762C189.644 30.4811 172.407 13.7044 151.144 13.7044C129.882 13.7044 112.645 30.4811 112.645 51.1762C112.645 71.8713 129.882 88.648 151.144 88.648Z"
                          fill="white" className="svg-elem-2"></path>
                    <path
                        d="M240.712 101.852H201.051V0.5H240.712C255.703 0.5 267.867 5.28609 277.375 14.9426C286.884 24.5991 291.595 36.6481 291.595 51.2616C291.595 65.8747 286.884 77.9242 277.375 87.4959C267.867 97.0663 255.702 101.852 240.712 101.852ZM240.712 19.386H222.295V82.9664H240.712C249.707 82.9664 256.816 80.0607 262.042 74.1644C267.267 68.3529 269.837 60.7472 269.837 51.2616C269.837 41.776 267.267 34.0847 262.042 28.188C256.816 22.2917 249.706 19.386 240.712 19.386Z"
                        fill="white" className="svg-elem-3"></path>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M341.501 68.663C352.272 68.663 360.038 60.3069 360.038 51.1762C360.038 42.0454 352.272 33.6893 341.501 33.6893C330.73 33.6893 322.964 42.0454 322.964 51.1762C322.964 60.3069 330.73 68.663 341.501 68.663ZM341.501 88.648C362.763 88.648 380 71.8713 380 51.1762C380 30.4811 362.763 13.7044 341.501 13.7044C320.238 13.7044 303.002 30.4811 303.002 51.1762C303.002 71.8713 320.238 88.648 341.501 88.648Z"
                          fill="white" className="svg-elem-4"></path>
                </svg>
            </div>
        </div>
    );
}

export default Loader;
