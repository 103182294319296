import axios from "axios";
import ApiConfig from "../config/api-config";

const config = {
  baseURL: ApiConfig.BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
const configForm = {
  baseURL: ApiConfig.BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data;",
  },
};

const getLocaleFromStorage = () => {
  return localStorage.getItem("locale") || "en";
};
const $apiClient = axios.create(config);
const $apiClientForm = axios.create(configForm);

$apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const locale = getLocaleFromStorage();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers["X-localization"] = locale;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
$apiClientForm.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const locale = getLocaleFromStorage();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers["X-localization"] = locale;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { $apiClient ,$apiClientForm };
