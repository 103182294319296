import { $apiClient } from "../../../utils/apiClient";

export function setAuthAccessToken(token) {
  localStorage.setItem("token", token);
  $apiClient.defaults.headers.Authorization = `Bearer ${token}`;
}

export function deleteAuthAccessToken() {
  localStorage.removeItem("token");
  delete $apiClient.defaults.headers.Authorization;
}
