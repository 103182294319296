import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.user || initialState;

export const selectUserData = createSelector(
  [selectDomain],
  (UserState) => UserState.data
);

export const selectUserLoading = createSelector(
  [selectDomain],
  (UserState) => UserState.fetch.loading
);

export const selectUserDone = createSelector(
  [selectDomain],
  (UserState) => UserState.fetch.done
);

export const selectUserError = createSelector(
  [selectDomain],
  (UserState) => UserState.fetch.error
);
