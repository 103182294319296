import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.oneGodo || initialState;

export const selectOneGodoData = createSelector(
  [selectDomain],
  (oneGodoState) => oneGodoState.data
);

export const selectOneGodoLoading = createSelector(
  [selectDomain],
  (oneGodoState) => oneGodoState.fetch.loading
);

export const selectOneGodoDone = createSelector(
  [selectDomain],
  (oneGodoState) => oneGodoState.fetch.done
);

export const selectOneGodoError = createSelector(
  [selectDomain],
  (oneGodoState) => oneGodoState.fetch.error
);
