import { call, put } from "redux-saga/effects";
import { actions as tasksaction } from "./slice";
import * as tasksService from "./services";

function* fetchTasksSaga() {
  try {
    const { data } = yield call(tasksService.getData);
    yield put(tasksaction.fetchDataSuccess(data));
  } catch (err) {
    yield put(tasksaction.fetchDataFailed(err));
  } finally {
    yield put(tasksaction.fetchDataFulfilled());
  }
}

export { fetchTasksSaga };
