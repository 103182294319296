import { call, put } from "redux-saga/effects";
import { actions as dashboardctions } from "./slice";
import * as dashboardService from "./services";

function* fetchDashboardSaga() {
  try {
    const { data } = yield call(dashboardService.getDashboard);
    yield put(dashboardctions.fetchDataSuccess(data));
  } catch (err) {
    yield put(dashboardctions.fetchDataFailed(err));
  } finally {
    yield put(dashboardctions.fetchDataFulfilled());
  }
}

export { fetchDashboardSaga };
