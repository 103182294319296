import { takeLatest } from "@redux-saga/core/effects";

import { autoLoginSaga } from "./modules/app/saga";
import { actions as appActions } from "./modules/app/slice";
//
import { fetchUserSaga } from "./modules/user/saga";
import { actions as userActions } from "./modules/user/slice";
//
import { actions as loginActions } from "./modules/login/slice";
import { fetchLoginSaga } from "./modules/login/saga";
//
import { actions as dashboardActions } from "./modules/dashboard/slice";
import { fetchDashboardSaga } from "./modules/dashboard/saga";
//
import { actions as tasksActions } from "./modules/allTasks/slice";
import { fetchTasksSaga } from "./modules/allTasks/saga";
//
import { actions as resetPasswordActions } from "./modules/resetPassword/slice";
import { fetchresetPasswordSaga } from "./modules/resetPassword/saga";
//
import { actions as newGodoActions } from "./modules/newGodo/slice";
import { fetchNewGodoSaga } from "./modules/newGodo/saga";
//
import { actions as oneGodoActions } from "./modules/oneGodo/slice";
import { fetchOneGodoSaga } from "./modules/oneGodo/saga";
//
import { actions as localeActions } from "./modules/locale/slice";
import { fetchLocaleSaga } from "./modules/locale/saga";
//
import { actions as refreshActions } from "./modules/refreshToken/slice";
import { fetchRefreshSaga } from "./modules/refreshToken/saga";
//
import { actions as categoriesActions } from "./modules/categories/slice";
import { fetchCategoriesSaga } from "./modules/categories/saga";
//
import { actions as editGodoActions } from "./modules/editGodo/slice";
import { fetchEditGodoSaga } from "./modules/editGodo/saga";
//
import { actions as deleteGodoActions } from "./modules/deleteGodo/slice";
import { fetchDeleteGodoSaga } from "./modules/deleteGodo/saga";
//
import { actions as restorePasswordActions } from "./modules/restorePassword/slice";
import { fetchrestorePasswordSaga } from "./modules/restorePassword/saga";
function* watchSagas() {
  yield takeLatest(appActions.autoLoginTrigger.type, autoLoginSaga);
  yield takeLatest(userActions.fetchDataTrigger.type, fetchUserSaga);
  yield takeLatest(loginActions.fetchDataTrigger.type, fetchLoginSaga);
  yield takeLatest(dashboardActions.fetchDataTrigger.type, fetchDashboardSaga);
  yield takeLatest(tasksActions.fetchDataTrigger.type, fetchTasksSaga);
  yield takeLatest(
    resetPasswordActions.fetchDataTrigger.type,
    fetchresetPasswordSaga
  );
  yield takeLatest(newGodoActions.fetchDataTrigger.type, fetchNewGodoSaga);
  yield takeLatest(oneGodoActions.fetchDataTrigger.type, fetchOneGodoSaga);
  yield takeLatest(localeActions.fetchDataTrigger.type, fetchLocaleSaga);
  yield takeLatest(refreshActions.fetchDataTrigger.type, fetchRefreshSaga);
  yield takeLatest(categoriesActions.fetchDataTrigger.type, fetchCategoriesSaga);
  yield takeLatest(editGodoActions.fetchDataTrigger.type, fetchEditGodoSaga);
  yield takeLatest(deleteGodoActions.fetchDataTrigger.type, fetchDeleteGodoSaga);
  yield takeLatest(restorePasswordActions.fetchDataTrigger.type, fetchrestorePasswordSaga);
}

export { watchSagas };
