import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./state";

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    autoLoginTrigger(state) {
      state.error = null;
    },
    autoLoginSetToken(state, action) {
      state.token = action.payload.token;
    },
    autoLoginRestrictToken(state) {
      state.token = null;
    },
  },
});

export const { actions, reducer, name } = appSlice;
