import {Input} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {actions as loginActions} from "../../../store/modules/login/slice";
import {useEffect} from "react";
import i18n from "i18next";
import {selectLoginDone} from "../../../store/modules/login/selector";
import {useTranslation} from "react-i18next";

const LoginForm = () => {
    const {t} = useTranslation();
    const {
        control,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginDone = useSelector(selectLoginDone);
    const handleNavigate = () => {
        navigate('/restore');
    };

    useEffect(() => {
        if (loginDone) {
            reset();
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginDone]);

    const onSubmit = (data) => {
        dispatch(loginActions.fetchDataTrigger(data));
    };

    return (
        <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className="login-form_"
            id="login-form"
        >
            <Controller
                name="email"
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: i18n.t("error_required"),
                    },
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t("error_email_invalid"),
                    },
                }}
                render={({field}) => (
                    <>
                        <Input
                            type="email"
                            name="email"
                            placeholder={t("login_email")}
                            {...field}
                            style={{
                                borderColor: errors.email ? "#E33C22" : "#D9D9D9",
                            }}
                        />
                        {errors.email && (
                            <span className="error-message">{errors.email.message}</span>
                        )}
                    </>
                )}
            />

            <Controller
                name="password"
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: t("error_required"),
                    },
                }}
                render={({field}) => (
                    <>
                        <Input.Password

                            placeholder={t("login_password")}
                            name="password"
                            {...field}
                            iconRender={(visible) =>
                                visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                            }
                            style={{
                                height: "40px",
                                borderColor: errors.password ? "#E33C22" : "#D9D9D9",
                            }}
                        />
                        {errors.password && (
                            <span className="error-message">{errors.password.message}</span>
                        )}
                    </>
                )}
            />
            <span
                style={{
                    margin: "-10px 0",
                    cursor: "pointer",
                    color: "#03B3B4",
                    fontFamily: "Roboto, sans-serif"
                }}
                onClick={handleNavigate}
            >
         {t("forgot_pass")}
        </span>
            <button className="submitbtn_login" type="submit">
                {t("login_title")}
            </button>
        </form>
    );
};

export default LoginForm;
