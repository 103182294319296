import { call, put } from "redux-saga/effects";
import jwtDecode from "jwt-decode";

import { setAuthAccessToken } from "./services";
import { actions as appActions } from "./slice";
// import { actions as logoutActions } from "store/modules/logout/slice";

export function* autoLoginSaga() {
  const token = yield localStorage.getItem("token");
  const value = false;

  if (token) {
    const decodedInfo = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedInfo?.exp > currentTime) {
      yield call(setAuthAccessToken, token);
      yield put(appActions.autoLoginSetToken({ token }));
    } else {
      //   yield put(logoutActions.fetchDataTrigger());
    }
  } else {
    yield put(appActions.autoLoginRestrictToken());
    // yield put(logoutActions.fetchDataTrigger());
  }
}
