import "./style.css";
import {Layout, Menu} from "antd";
import React, {useState} from "react";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {DashboardOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UnorderedListOutlined,} from "@ant-design/icons";
import {actions as dashboardActions} from "../../store/modules/dashboard/slice";
import {useDispatch} from "react-redux";

const {Sider} = Layout;

function Sidebar() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useDispatch();

    const handleClickDashbord = () => {
        if (location.pathname !== '/') {
            dispatch(dashboardActions.fetchDataTrigger());
            navigate("/");
        }
    };
    const handleClickTasks = () => {
        navigate("/tasks");
    };
    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={208}
            theme="light"
        >
            <Menu
                style={{paddingTop: "12px", width: "100%"}}
                theme="light"
                mode="inline"

            >
                <Menu.Item
                    key="1"
                    style={{
                        margin: "0",
                        marginBlock: "0",
                        marginInline: "0",
                        borderRadius: "0",
                        width: "100%",
                        border: "none",
                    }}
                    onClick={handleClickDashbord}
                    className={location.pathname === "/" ? "ant-menu-item-selected" : ""}
                >
                    <DashboardOutlined/>
                    <span className="active-item">{i18n.t("dashboard_menu_main")}</span>
                </Menu.Item>
                <Menu.Item
                    style={{
                        margin: "0",
                        borderRadius: "0",
                        width: "100%",
                        border: "none",
                        background: "none",
                    }}
                    key="sub1"
                    onClick={handleClickTasks}
                    className={location.pathname === "/tasks" ? "ant-menu-item-selected" : ""}

                >
                    <UnorderedListOutlined style={{fill: "white"}}/>
                    <span>{i18n.t("dashboard_menu_all_tasks")}</span>
                </Menu.Item>
                <a onClick={() => navigate("/new-godo")} className="new-godo">
                    <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.5312 10.4298H0.46875C0.226758 10.4298 0.03125 10.6253 0.03125 10.8673V11.3595C0.03125 11.4197 0.0804687 11.4689 0.140625 11.4689H10.8594C10.9195 11.4689 10.9688 11.4197 10.9688 11.3595V10.8673C10.9688 10.6253 10.7732 10.4298 10.5312 10.4298ZM2.02324 9.28137C2.05059 9.28137 2.07793 9.27864 2.10527 9.27454L4.40488 8.87122C4.43223 8.86575 4.4582 8.85344 4.47734 8.83293L10.2729 3.03743C10.2855 3.02478 10.2956 3.00975 10.3024 2.99322C10.3093 2.97668 10.3128 2.95895 10.3128 2.94104C10.3128 2.92313 10.3093 2.9054 10.3024 2.88886C10.2956 2.87233 10.2855 2.8573 10.2729 2.84465L8.00059 0.571021C7.97461 0.545044 7.94043 0.531372 7.90352 0.531372C7.8666 0.531372 7.83242 0.545044 7.80645 0.571021L2.01094 6.36653C1.99043 6.38704 1.97812 6.41164 1.97266 6.43899L1.56934 8.7386C1.55604 8.81184 1.56079 8.88722 1.58318 8.95821C1.60557 9.0292 1.64493 9.09367 1.69785 9.14602C1.78809 9.23352 1.90156 9.28137 2.02324 9.28137Z"
                            fill="white"
                        />
                    </svg>
                    {collapsed ? "" : <span>{i18n.t("dashboard_menu_new_godo")}</span>}
                </a>
            </Menu>
            <div
                className="ant-layout-sider-trigger"
                onClick={() => setCollapsed(!collapsed)}
            >
                {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}{" "}
            </div>
        </Sider>
    );
}

export default Sidebar;
