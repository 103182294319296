import { Input } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as restorePasswordActions } from "../../../store/modules/restorePassword/slice";
import { useEffect } from "react";
import {selectrestorePasswordData, selectrestorePasswordLoading} from "../../../store/modules/restorePassword/selector";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const RestoreForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetDone = useSelector(selectrestorePasswordData);
  const resetLoading = useSelector(selectrestorePasswordLoading)
  const { t } = useTranslation();

  useEffect(() => {
    if (resetDone) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDone]);

  const onSubmit = (data) => {
    dispatch(restorePasswordActions.fetchDataTrigger(data));
  };

  return (
      <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="login-form"
          id="restore-form"
      >
          <Controller
              name="email"
              control={control}
              rules={{
                  required: {
                      value: true,
                      message: i18n.t("error_required"),
                  },
                  pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("error_email_invalid"),
                  },
              }}
              render={({field}) => (
                  <>
                      <Input
                          type="email"
                          name="email"
                          placeholder={t("login_email")}
                          {...field}
                          style={{
                              borderColor: errors.email ? "#E33C22" : "#D9D9D9",
                          }}
                      />
                      {errors.email && (
                          <span className="error-message">{errors.email.message}</span>
                      )}
                  </>
              )}
          />
          <button disabled={resetLoading} className="submitbtn" type="submit">
              {resetLoading ? <i className="fa fa-spinner fa-spin"></i> : i18n.t("dashboard_confirm")}
          </button>
      </form>
  );
};

export default RestoreForm;
