import React from 'react';
import "./style.css";
import DashboardHead from "../../components/dashboard/dashboardHead";
import DashboardStats from "../../components/dashboard/dashboardStats";
import DashboardTotal from "../../components/dashboard/dashboardTotal";

function Dashboard() {
    return (
        <>
            <DashboardHead/>
            <DashboardStats/>
            <DashboardTotal/>
        </>
    );
}

export default Dashboard;
