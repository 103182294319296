import { call, put } from "redux-saga/effects";
import { actions as editGodoActions } from "./slice";
import * as editGodoService from "./services";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18next";
function* fetchEditGodoSaga({ payload }) {
const MySwal = withReactContent(Swal);
  try {
    const { data } = yield call(editGodoService.postEditGodo, payload);
    yield put(editGodoActions.fetchDataSuccess(data));
    setTimeout(() => {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "success",
        title: i18n.t("dashboard_edit_godo_popup_title"),
      });
    }, 400);
  } catch (err) {
    yield put(editGodoActions.fetchDataFailed(err));
  } finally {
    yield put(editGodoActions.fetchDataFulfilled());
  }
}

export { fetchEditGodoSaga };
