import { call, put } from "redux-saga/effects";
import { actions as CategoriesActions } from "./slice";
import * as categoriesService from "./services";

function* fetchCategoriesSaga() {
  try {
    const { data } = yield call(categoriesService.getCategories);
    yield put(CategoriesActions.fetchDataSuccess(data));
  } catch (err) {
    yield put(CategoriesActions.fetchDataFailed(err));
  } finally {
    yield put(CategoriesActions.fetchDataFulfilled());
  }
}

export { fetchCategoriesSaga };
