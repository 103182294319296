import "./style.css";
import OneGodoGallery from "../../components/one-godo";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {actions as oneGodoActions} from "../../store/modules/oneGodo/slice";
import {selectOneGodoData} from "../../store/modules/oneGodo/selector";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Badly, Fine, Normally, Satisfied} from "../../assets/experience";

function OneGodo() {
    const {t} = useTranslation();

    const formatDuration = (minutes) => {
        if (minutes < 60) {
            return `${minutes} ${i18n.t("dashboard_one_godo_min")}`;
        } else {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours}${remainingMinutes > 0 ? `:${remainingMinutes} ${i18n.t("dashboard_one_godo_hours")}` : ` ${i18n.t("dashboard_one_godo_hours")}`}`;
        }
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const id = window.location.pathname.split("/").pop();
        dispatch(oneGodoActions.fetchDataTrigger(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = useSelector(selectOneGodoData);

    useEffect(() => {
        const handleLanguageChange = () => {
            const id = window.location.pathname.split("/").pop();
            dispatch(oneGodoActions.fetchDataTrigger(id));
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [dispatch]);
    if (data) {
        const formattedDuration = formatDuration(data.data.duration);
        return (
            <div className="one-godo-wrapper">
                <div className="one-godo-left">
                    <div className="one-godo-left-head">
                        <a
                            onClick={() => {
                                navigate("/tasks");
                            }}
                        >
                            <svg
                                width="15"
                                height="16"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.85888 8L8.44437 10.5855C8.83489 10.976 8.91404 11.53 8.62114 11.8229C8.32825 12.1158 7.77423 12.0367 7.38371 11.6462L4.55528 8.81772C4.31199 8.57443 4.18955 8.26769 4.20262 8.00016C4.18944 7.73256 4.31189 7.42568 4.55527 7.1823L7.38369 4.35387C7.77422 3.96335 8.32824 3.8842 8.62113 4.17709C8.91402 4.46999 8.83488 5.02401 8.44435 5.41453L5.85888 8Z"
                                    fill="black"
                                    fillOpacity="0.85"
                                />
                            </svg>
                        </a>
                        <span>{data.data.title}</span>
                    </div>
                    <div className="one-godo-left-content">
                        <div className="description">
                            <p className="description-title">
                                {i18n.t("dashboard_table_one_godo_desc")}
                            </p>
                            <div className="description-content">{data.data.description}</div>
                            <div className="badge"
                                 style={{backgroundColor: data.data.category.data.color}}>{data.data.category.data.title}</div>
                            <div className="description-status">
                                {data.data.date !== 0 && data.data.date !== null && data.data.date.trim() !== "" && (
                                    <div className="description-status-item">
                                    <div className="description-status-item-title">
                                        {i18n.t("dashboard_table_one_godo_data")}
                                    </div>
                                    <div className="description-status-item-desc">
                                        {data.data.date}
                                    </div>
                                </div>
                                )}
                                {data.data.duration !== 0 && data.data.duration !== null && (
                                    <div className="description-status-item">
                                        <div className="description-status-item-title">
                                            {i18n.t("dashboard_table_one_godo_duraction")}
                                        </div>
                                        <div className="description-status-item-desc">
                                            {formattedDuration}
                                        </div>
                                    </div>
                                )}
                                {data.data.actions && data.data.actions.data.some(action => action.experience) && (
                                    <div className="description-status-item">
                                        <div className="description-status-item-title">
                                            {i18n.t("dashboard_table_one_godo_experience")}
                                        </div>
                                        <div className="description-status-item-desc">
                                            <div className="flex-item">
                                                {data.data.actions.data
                                                    .filter(action => action.experience) // Filter out actions without experience
                                                    .map((action, index) => (
                                                        <div key={index} className="description-status-item-flex">
                                                            <div className="description-status-item-desc desc-wr">
                                                                <div className="status-wr">
                                                                    {action.user.data.image ?
                                                                        <img src={action.user.data.image} alt=""/> :
                                                                        <img src="/avatar.png" alt=""/>}
                                                                    <div className="status-user-info">
                                                                        <p>{action.user.data.name}</p>
                                                                        {(() => {
                                                                            if (action.experience === "badly") {
                                                                                return <Badly/>;
                                                                            } else if (action.experience === "normally") {
                                                                                return <Normally/>;
                                                                            } else if (action.experience === "fine") {
                                                                                return <Fine/>;
                                                                            } else if (action.experience === "satisfied") {
                                                                                return <Satisfied/>;
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {data.data.actions && (
                                    <div
                                        className="description-status-item-title">{i18n.t("dashboard_table_status")}</div>
                                )}
                                <div className="flex-item">
                                    {data.data.actions &&
                                        data.data.actions.data.map((action, index) => (
                                            <div key={index} className="description-status-item-flex">
                                                <div className="description-status-item-desc desc-wr">
                                                    <div className="status-wr">
                                                        {action.user.data.image ?
                                                            <img src={action.user.data.image} alt=""/> :
                                                            <img src="/avatar.png" alt=""/>}
                                                        <div className="status-user-info">
                                                            <p>{action.user.data.name}</p>
                                                            <div
                                                                className={`stat-badge ${
                                                                    action.status === "doing"
                                                                        ? "doing"
                                                                        : action.status === "Not successful"
                                                                            ? "nots"
                                                                            : action.status === "Done"
                                                                                ? "done"
                                                                                : "done"
                                                                }`}
                                                            >
                                                                <div className="badges"></div>
                                                                <span>{action.status}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="one-godo-right">
                    <OneGodoGallery/>
                </div>
            </div>
        )
            ;
    }
}

export default OneGodo;
