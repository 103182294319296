import { call, put } from "redux-saga/effects";
import { actions as refreshAction } from "./slice";
import * as refreshService from "./services";
import { setAuthAccessToken } from "../app/services";
import { actions as appActions } from "../app/slice";
import {useNavigate} from "react-router-dom";

function* fetchRefreshSaga({ payload }) {
  try {
    const { data } = yield call(refreshService.postToken, payload);
    yield put(refreshAction.fetchDataSuccess(data));
    yield put(appActions.autoLoginSetToken({ token: data.access_token }));
    yield call(setAuthAccessToken, data.access_token);
  } catch (err) {
    if (err.response && err.response.status === 400) {
      localStorage.clear();
      window.location.reload();
      window.location.href = "/";
    }
    yield put(refreshAction.fetchDataFailed(err));
  } finally {
    yield put(refreshAction.fetchDataFulfilled());
  }
}

export { fetchRefreshSaga };
