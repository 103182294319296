import "./App.css";
import "../src/locales/i18n";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Login from "./screens/login";
import Dashboard from "./screens/dashboard";
import DashboardLayout from "./layout/DashboardLayout";
import NewGodo from "./screens/newGodo";
import Tasks from "./screens/tasks";
import OneGodo from "./screens/oneGodo";
import RestorePassword from "./screens/restorePassword";
import PrivacyPolicy from "./screens/privacyPolicy";
import TermsOfUse from "./screens/termsOfUse";


import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated } from "./store/modules/app/selector";
import { useEffect, useState } from "react";
import { actions as appActions } from "./store/modules/app/slice";
import { actions as localeActions } from "./store/modules/locale/slice";
import withError from "./hoc/withError";
import { $apiClient } from "./utils/apiClient";
import Loader from "./layout/Loader";
import EditGodo from "./screens/editGodo";
import ResetPassword from "./screens/resetPassword";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    dispatch(localeActions.fetchDataTrigger());
  }, [dispatch]);

  const onTryAutoLogin = () => {
    dispatch(appActions.autoLoginTrigger());
  };

  useEffect(() => {
    onTryAutoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const email = urlParams.get("email");

    if (token && email) {
      return { token, email };
    }

    return null;
  };

  return (
    <BrowserRouter>
      <LocationChangeHandler />
      <Routes>
        {isAuthenticated !== null ? (
          <>
            <Route path="/" element={<DashboardLayout />}>
              <Route index element={<Dashboard />} />
            </Route>
            <Route path="/new-godo" element={<DashboardLayout />}>
              <Route index element={<NewGodo />} />
            </Route>
            <Route path="/tasks" element={<DashboardLayout />}>
              <Route index element={<Tasks />} />
            </Route>
            <Route path="/edit/:slug" element={<DashboardLayout />}>
              <Route index element={<EditGodo />} />
            </Route>
            <Route path="/tasks/:slug" element={<DashboardLayout />}>
              <Route index element={<OneGodo />} />
            </Route>
          </>
        ) : (
          <>
            <Route
              path="/reset-password"
              element={<RestorePassword {...getQueryParams()} />}
            />
            <Route path="/" element={<Login />} />
            <Route path="/restore" element={<ResetPassword />} />
          </>
        )}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />

        {/*<Route path="/login" element={<Login />} />*/}
        <Route
          path="*"
          element={<Navigate to={isAuthenticated ? "/" : "/"} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

function LocationChangeHandler() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [location]);

  if (loading) {
    return <Loader />;
  }
  return null;
}

export default withError(App, $apiClient);
