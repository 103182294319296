import React from "react";
import { Typography } from "antd";
import "./style.css";

const { Title, Paragraph } = Typography;

const TermsOfUse = () => {
  return (
    <div className="container">
      <Title level={2} className="title">
        Terms and Conditions
      </Title>
      <Paragraph className="date">Last updated: August 23, 2024</Paragraph>

      <div className="content">
        <Paragraph>
          Please read these terms and conditions carefully before using Our
          Service.
        </Paragraph>

        <Title level={4}>1. Definitions</Title>
        <Paragraph>For the purposes of these Terms and Conditions:</Paragraph>
        <Paragraph>
          <Paragraph>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest or other securities entitled to vote for election of
            directors or other managing authority.
          </Paragraph>
          <Paragraph>
            <strong>Application</strong> means the software program provided by
            the Company downloaded by You on any electronic device, named GoDo
            Tracker
          </Paragraph>
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </Paragraph>
        <Paragraph>
          <strong>Country</strong> refers to: Finland
        </Paragraph>
        <Paragraph>
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to GoDo Ecosystem Oy Ab,
          BusinessID 3415475-3, Luotsikatu 11 B12, 00160 Helsinki, Finland.
        </Paragraph>
        <Paragraph>
          <strong>Content</strong> refers to content such as text, images, or
          other information that can be posted, uploaded, linked to or otherwise
          made available by You, regardless of the form of that content.
        </Paragraph>
        <Paragraph>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a mobile phone or a digital tablet.
        </Paragraph>
        <Paragraph>
          <strong>Service</strong> refers to the Application.
        </Paragraph>
        <Paragraph>
          <strong>Terms and Conditions</strong> (also referred as "Terms") mean
          these Terms and Conditions that form the entire agreement between You
          and the Company regarding the use of the Service.
        </Paragraph>
        <Paragraph>
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </Paragraph>

        <Title level={4}>2. Acknowledgment</Title>
        <Paragraph>
          These are the Terms and Conditions governing the use of this Service
          and the agreement that operates between You and the Company. These
          Terms and Conditions set out the rights and obligations of all users
          regarding the use of the Service.
        </Paragraph>
        <Paragraph>
          Your access to and use of the Service is conditioned on Your
          acceptance of and compliance with these Terms and Conditions. These
          Terms and Conditions apply to all visitors, users and others who
          access or use the Service.
        </Paragraph>
        <Paragraph>
          By accessing or using the Service You agree to be bound by these Terms
          and Conditions. If You disagree with any part of these Terms and
          Conditions then You may not access the Service.
        </Paragraph>
        <Paragraph>
          Your access to and use of the Service is also conditioned on Your
          acceptance of and compliance with the Privacy Policy of the Company.
          Our Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your personal information when You
          use the Application and tells You about Your privacy rights and how
          the law protects You. Please read Our Privacy Policy carefully before
          using Our Service.
        </Paragraph>

        <Title level={4}>3. User Accounts</Title>
        <Paragraph>
          When You create an account with Us, You must provide Us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of Your account on Our Service.
        </Paragraph>
        <Paragraph>
          You are responsible for safeguarding the password that You use to
          access the Service and for any activities or actions under Your
          password.
        </Paragraph>
        <Paragraph>
          You agree not to disclose Your password to any third party. You must
          notify Us immediately upon becoming aware of any breach of security or
          unauthorized use of Your account.
        </Paragraph>
        <Paragraph>
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than You
          without appropriate authorization, or a name that is otherwise
          offensive, vulgar or obscene.
        </Paragraph>

        <Title level={4}>4. Your Right to Post Content</Title>
        <Paragraph>
          Our Service allows You to post Content. You are responsible for the
          Content that You post to the Service, including its legality,
          reliability, and appropriateness.
        </Paragraph>
        <Paragraph>
          By posting Content to the Service, You grant Us the right and license
          to use, modify, publicly perform, publicly display, reproduce, and
          distribute such Content on and through the Service. You retain any and
          all of Your rights to any Content You submit, post or display on or
          through the Service and You are responsible for protecting those
          rights. You agree that this license includes the right for Us to make
          Your Content available to other users of the Service, who may also use
          Your Content subject to these Terms.
        </Paragraph>
        <Paragraph>
          We reserve the right, at our sole discretion, to review, modify, or
          remove any user content that violates our policies, is deemed
          inappropriate, offensive or illegal. This includes, but is not limited
          to, content that is harmful, abusive, defamatory, obscene, or in
          violation of the rights of others. We may take such actions without
          prior notice or liability to you, and we are not obligated to retain
          or provide copies of any content that has been removed.
        </Paragraph>

        <Title level={4}>5. Content Backups</Title>
        <Paragraph>
          Although regular backups of Content are performed, the Company does
          not guarantee there will be no loss or corruption of data.
        </Paragraph>
        <Paragraph>
          Corrupt or invalid backup points may be caused by, without limitation,
          Content that is corrupted prior to being backed up or that changes
          during the time a backup is performed.
        </Paragraph>
        <Paragraph>
          The Company will provide support and attempt to troubleshoot any known
          or discovered issues that may affect the backups of Content. But You
          acknowledge that the Company has no liability related to the integrity
          of Content or the failure to successfully restore Content to a usable
          state.
        </Paragraph>

        <Title level={4}>6. Termination</Title>
        <Paragraph>
          We may terminate or suspend Your Account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if You breach these Terms and Conditions. Upon termination,
          Your right to use the Service will cease immediately.
        </Paragraph>
        <Paragraph>
          If You wish to terminate Your Account, You can delete Your Account, in
          the Service.
        </Paragraph>

        <Title level={4}>7. "AS IS" and "AS AVAILABLE" Disclaimer</Title>
        <Paragraph>
          The Service is provided to You "AS IS" and "AS AVAILABLE" and with all
          faults and defects without warranty of any kind. To the maximum extent
          permitted under applicable law, the Company, on its own behalf and on
          behalf of its Affiliates and its and their respective licensors and
          service providers, expressly disclaims all warranties, whether
          express, implied, statutory or otherwise, with respect to the Service,
          including all implied warranties of merchantability, fitness for a
          particular purpose, title and non-infringement, and warranties that
          may arise out of course of dealing, course of performance, usage or
          trade practice. Without limitation to the foregoing, the Company
          provides no warranty or undertaking, and makes no representation of
          any kind that the Service will meet Your requirements, achieve any
          intended results, be compatible or work with any other software,
          applications, systems or services, operate without interruption, meet
          any performance or reliability standards or be error free or that any
          errors or defects can or will be corrected.
        </Paragraph>
        <Paragraph>
          Without limiting the foregoing, neither the Company nor any of the
          company's provider makes any representation or warranty of any kind,
          express or implied: (i) as to the operation or availability of the
          Service, or the information, content, and materials or products
          included thereon; (ii) that the Service will be uninterrupted or
          error-free; (iii) as to the accuracy, reliability, or currency of any
          information or content provided through the Service; or (iv) that the
          Service, its servers, the content, or e-mails sent from or on behalf
          of the Company are free of viruses, scripts, trojan horses, worms,
          malware, timebombs or other harmful components.
        </Paragraph>
        <Paragraph>
          Some jurisdictions do not allow the exclusion of certain types of
          warranties or limitations on applicable statutory rights of a
          consumer, so some or all of the above exclusions and limitations may
          not apply to You. But in such a case the exclusions and limitations
          set forth in this section shall be applied to the greatest extent
          enforceable under applicable law.
        </Paragraph>

        <Title level={4}>8. Governing Law</Title>
        <Paragraph>
          The laws of the Country, excluding its conflicts of law rules, shall
          govern this Terms and Your use of the Service. Your use of the
          Application may also be subject to other local, state, national, or
          international laws.
        </Paragraph>

        <Title level={4}>9. Disputes Resolution</Title>
        <Paragraph>
          If You have any concern or dispute about the Service, You agree to
          first try to resolve the dispute informally by contacting the Company.
        </Paragraph>

        <Title level={4}>10. For European Union (EU) Users</Title>
        <Paragraph>
          If You are a European Union consumer, you will benefit from any
          mandatory provisions of the law of the country in which You are
          resident.
        </Paragraph>

        <Title level={4}>11. Changes to These Terms and Conditions</Title>
        <Paragraph>
          We reserve the right, at Our sole discretion, to modify or replace
          these Terms at any time. If a revision is material We will make
          reasonable efforts to provide at least 30 days' notice prior to any
          new terms taking effect. What constitutes a material change will be
          determined at Our sole discretion.
        </Paragraph>
        <Paragraph>
          By continuing to access or use Our Service after those revisions
          become effective, You agree to be bound by the revised terms. If You
          do not agree to the new terms, in whole or in part, please stop using
          the website and the Service.
        </Paragraph>

        <Title level={4}>Contact Us</Title>
        <Paragraph>
          If you have any questions about these Terms and Conditions, You can
          contact our Data Protection Officer. By email:{" "}
          <a href="mailto:dpo@godoecosystem.com">dpo@godoecosystem.com</a>.
        </Paragraph>
      </div>
    </div>
  );
};

export default TermsOfUse;
