import getUrlQuery from "../utils/getUrlQuery";

const ApiConfig = {
  BASE_URL: process.env.REACT_APP_API_URL,
  USERS: `/api/v1/org_auth/me`,
  LOGIN: `/api/v1/org_auth/login/`,
  DASHBOARD: `/api/v1/org_auth/dashboard`,
  TASKS: `/api/v1/org_auth/tasks`,
  RESETPASSWORD: `/api/v1/org_auth/password/restore`,
  NEWGODO: `/api/v1/org_auth/tasks/store`,
  ONEGODO: `/api/v1/org_auth/tasks/`,
  LOCALE: `/api/v1/translations`,
  REFRESH: `/api/v1/org_auth/refresh`,
  CATEGORIES :`/api/v1/org_auth/tasks/get_categories`,
  EDITGODO :`/api/v1/org_auth/tasks/update/`,
  DELETE :`/api/v1/org_auth/tasks/delete/`,
  RESTORE:`/api/v1/org_auth/password/reset?type=reset`
};
export default ApiConfig;
