import { call, put } from "redux-saga/effects";
import { actions as userAction } from "./slice";
import * as userService from "./services";

function* fetchUserSaga({ payload }) {
  try {
    const { data } = yield call(userService.getData, payload);
    yield put(userAction.fetchDataSuccess(data));
  } catch (err) {
    yield put(userAction.fetchDataFailed(err));
  } finally {
    yield put(userAction.fetchDataFulfilled());
  }
}

export { fetchUserSaga };
