import { compose, createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import { watchSagas } from "./watchSagas";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const reducers = combineReducers(rootReducer);

const configureAppStore = () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducers,
    {},
    composeEnhancers(
      applyMiddleware(...middlewares)
    )
  );

  sagaMiddleware.run(watchSagas);

  return store;
};

const store = configureAppStore();

export default store;
