import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Controller,Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { actions as oneGodoActions } from "../../store/modules/oneGodo/slice";
import { selectOneGodoData } from "../../store/modules/oneGodo/selector";
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

function OneGodoGallery() {
    const { t } = useTranslation();
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const [modalOpen, setModalOpen] = useState(false); // State to control modal open/close
    const [selectedImageIndex, setSelectedImageIndex] = useState(0); // State to store index of selected image
    const dispatch = useDispatch();
    const data = useSelector(selectOneGodoData);

    useEffect(() => {
        if (!data) {
            const id = window.location.pathname.split("/").pop();
            dispatch(oneGodoActions.fetchDataTrigger(id));
        }
    }, [dispatch, data]);

    const openModal = (index) => {
        setSelectedImageIndex(index);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    if (data && data.data.images) {
        const images = data.data.images.data;
        const slides = images.map((image, index) => (
            <SwiperSlide key={index} onClick={() => openModal(index)}>
                <img src={image.image} alt={`Slide ${index}`} />
            </SwiperSlide>
        ));

        const galleryItems = images.map((image, index) => ({
            src: image.image,
            thumb: image.image,
            caption: `Slide ${index}`,
        }));

        return (
            <div>
                <Swiper
                    className="mySwiper1"
                    modules={[Controller]}
                    centeredSlides={true}
                    onSwiper={setFirstSwiper}
                    spaceBetween={10}
                    controller={{ control: secondSwiper }}
                >
                    {slides}
                </Swiper>
                <Swiper
                    className="mySwiper2"
                    modules={[Controller]}
                    controller={{ control: firstSwiper }}
                    slidesPerView={3}
                    centeredSlides={true}
                    spaceBetween={6.31}
                    onSwiper={setSecondSwiper}
                >
                    {slides}
                </Swiper>
                <Modal
                    isOpen={modalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Gallery Modal"
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <Swiper
                        className="mySwiper3"
                        modules={[Navigation]}
                        centeredSlides={true}
                        spaceBetween={10}
                        initialSlide={selectedImageIndex}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                    >
                        {slides}
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                    </Swiper>
                </Modal>
            </div>
        );
    } else {
        return null;
    }
}

export default OneGodoGallery;
