import "./style.css";
import StatsItem from "./item";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as dashboardActions } from "../../../store/modules/dashboard/slice";
import {
  UserOutlined,
  BarsOutlined,
  CheckOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { selectDashboardData } from "../../../store/modules/dashboard/selector";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function DashboardStats() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(selectDashboardData);
  const [lastUpdated, setLastUpdated] = useState(null);

  const getLastUpdated = () => {
    const localLastUpdated = JSON.parse(localStorage.getItem("last_updated"));
    if (localLastUpdated) {
      setLastUpdated(new Date(localLastUpdated));
    }
  };

  useEffect(() => {
    getLastUpdated();
    if (!data) dispatch(dashboardActions.fetchDataTrigger());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((fieldName) => {
        localStorage.setItem(fieldName, data[fieldName]);
      });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((fieldName) => {
        localStorage.setItem(fieldName, data[fieldName]);
      });
    }
  }, [data]);

  const calculatePercent = (fieldName) => {
    if (lastUpdated && data) {
      const localValue = parseInt(localStorage.getItem(fieldName)) || 0;
      var diff = data[fieldName] - localValue;
      return diff;
    }
    return 0;
  };

  const statsData = [
    {
      title: i18n.t("dashboard_users_title"),
      ico: <UserOutlined />,
      value: data ? data.users_count : 0,
      subTitle: i18n.t("dashboard_new_user"),
      percent: data ?  data.new_users:0,
    },
    {
      title: i18n.t("dashboard_all_tasks_title"),
      ico: <BarsOutlined />,
      value: data ? data.tasks_count : 0,
      subTitle: i18n.t("dashboard_new_task"),
      percent: data?data.new_tasks:0
    },
    {
      title: i18n.t("dashboard_all_complete_tasks_title"),
      ico: <CheckOutlined />,
      value: data ? data.completed_tasks : 0,
      subTitle: i18n.t("dashboard_all_task"),
      percent: data? data.closed_tasks_for_today:0
    },
    {
      title: i18n.t("dashboard_time_spend_tasks_title"),
      ico: <ClockCircleOutlined />,
      value: data ? data.time_spent_minutes : 0,
      subTitle: i18n.t("dashboard_task_complition_time"),
      percent: data? data.time_spent_minutes_today:0
    },
  ];
  return (
    <div className="dashboard-stats">
      {statsData.map((item, index) => (
        <div key={index} className="dashboard-stats-item">
          <StatsItem
            title={item.title}
            ico={item.ico}
            value={item.value}
            subTitle={item.subTitle}
            percent={item.percent}
          />
        </div>
      ))}
    </div>
  );
}

export default DashboardStats;
