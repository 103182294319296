import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions as refresActions } from "../../src/store/modules/refreshToken/slice"; 

const useHttpClient = (httpClientInstance) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [isRefreshingInProgress, setIsRefreshingInProgress] = useState(false); 

  const onRefresh = async () => {
    if (!refreshing && !isRefreshingInProgress) { 
      setRefreshing(true);
      try {
        setIsRefreshingInProgress(true); 
        const response = await dispatch(refresActions.fetchDataTrigger());
        const newToken = response.data.token; 
        localStorage.setItem('token', newToken);
        window.location.reload();
      } catch (err) {
        console.log(err);
      } finally {
        setIsRefreshingInProgress(false); 
        setRefreshing(false);
      }
    }
  };

  // request interceptor
  const reqInterceptor = httpClientInstance.interceptors.request.use(
    async (config) => {
      setError(null);
      if (config.data) {
        config.data =
          config.data instanceof FormData
            ? config.data
            : JSON.parse(JSON.stringify(config.data));
      }
      const bearerToken = await localStorage.getItem("token");
      if (bearerToken) {
        config.headers.Authorization = `Bearer ${bearerToken}`;
      }
      return config;
    },
    (err) => {
      setError(err);
      return Promise.reject(err);
    }
  );

  const resInterceptor = httpClientInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      let formattedMessage = null;

      if (err?.response) {
        switch (err.response.status) {
          case 401:
            onRefresh();
            break;
          case 500:
            formattedMessage = "Unknown error";
            break;
          default:
            break;
        }

        if (err.response.data?.message) {
          formattedMessage = err.response.data.message;
        }

        if (err.response.data?.errors) {
          Object.keys(err.response.data.errors).forEach((errorKey) => {
            err.response.data.errors[errorKey].forEach((line) => {
              formattedMessage += `\r\n${line}`;
            });
          });
        }

        if (err.response.status !== 401) {
          setError(formattedMessage);
        }
      }

      return Promise.reject(err);
    }
  );

  useEffect(() => {
    return () => {
      httpClientInstance.interceptors.request.eject(reqInterceptor);
      httpClientInstance.interceptors.response.eject(resInterceptor);
    };
  }, [reqInterceptor, resInterceptor]);

  const errorClearedHandler = () => {
    setError(null);
  };

  return [error, errorClearedHandler];
};

export default useHttpClient;
