import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.dashboard || initialState;

export const selectDashboardData = createSelector(
  [selectDomain],
  (DashboardState) => DashboardState.data
);

export const selectDashboardLoading = createSelector(
  [selectDomain],
  (DashboardState) => DashboardState.fetch.loading
);

export const selectDashboardDone = createSelector(
  [selectDomain],
  (DashboardState) => DashboardState.fetch.done
);

export const selectDashboardError = createSelector(
  [selectDomain],
  (DashboardState) => DashboardState.fetch.error
);
