import React from "react";
import { Typography } from "antd";
import "./style.css";

const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <Title level={2} className="title">
        Privacy Policy
      </Title>
      <Paragraph className="date">Last updated: August 23, 2024</Paragraph>

      <div className="content">
        <Title level={3}>Introduction</Title>
        <Paragraph>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </Paragraph>
        <Paragraph>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </Paragraph>
        <Paragraph>
          We follow the EU GDPR data protection requirements and accountability
          principles.
        </Paragraph>

        <Title level={3}>Definitions</Title>
        <Paragraph>For the purposes of this Privacy Policy:</Paragraph>
        <Paragraph>
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </Paragraph>
        <Paragraph>
          <strong>Affiliate</strong> means an entity that controls, is
          controlled by or is under common control with a party, where "control"
          means ownership of 50% or more of the shares, equity interest or other
          securities entitled to vote for election of directors or other
          managing authority.
        </Paragraph>
        <Paragraph>
          <strong>Application</strong> refers to GoDo Tracker, the software
          program provided by the Company.
        </Paragraph>
        <Paragraph>
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to GoDo Ecosystem Oy Ab,
          BusinessID 3415475-3, Luotsikatu 11 B12, 00160 Helsinki, Finland.
        </Paragraph>
        <Paragraph>
          <strong>Country</strong> refers to: Finland
        </Paragraph>
        <Paragraph>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cellphone or a digital tablet.
        </Paragraph>
        <Paragraph>
          <strong>Personal Data</strong> is any information that relates to an
          identified or identifiable individual.
        </Paragraph>
        <Paragraph>
          <strong>Service</strong> refers to the Application.
        </Paragraph>
        <Paragraph>
          <strong>Service Provider</strong> means any natural or legal person
          who processes the data on behalf of the Company. It refers to
          third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service or to assist the
          Company in analyzing how the Service is used.
        </Paragraph>
        <Paragraph>
          <strong>Usage Data</strong> efers to data collected automatically,
          either generated by the use of the Service or from the Service
          infrastructure itself (for example, the duration of a page visit).
        </Paragraph>
        <Paragraph>
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </Paragraph>

        <Title level={3}>Collecting and Using Your Personal Data</Title>
        <Title level={4}>Types of Data Collected</Title>
        <Title level={4}>Personal Data</Title>
        <Paragraph>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </Paragraph>
        <ul className="list">
          <li>● Email address</li>
          <li>● Phone number</li>
        </ul>

        <Title level={4}>
          Information Collected while Using the Application
        </Title>
        <Paragraph>
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission:
        </Paragraph>
        <ul className="list">
          <li>● Usage Data</li>
          <li>● Information regarding your location</li>
          <li>
            ● Pictures and other information from your Device's camera and photo
            library
          </li>
        </ul>

        <Paragraph>
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it may be
          simply stored on Your device.
        </Paragraph>
        <Paragraph>
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </Paragraph>

        <Title level={4}>Use of Your Personal Data</Title>
        <Paragraph>
          The Company may use Personal Data for the following purposes:
        </Paragraph>
        <ul>
          <li>
            ● To provide and maintain our Service, including to monitor the
            usage of our Service.
          </li>
          <li>
            ● To manage Your Account: to manage Your registration as a user of
            the Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </li>
          <li>
            ● To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </li>
          <li>
            ● To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            as long as You have opted in to receive such information.
          </li>
          <li>
            ● To manage Your requests: To attend and manage Your requests to Us.
          </li>
        </ul>

        <Paragraph>
          For other purposes: We may use Your information for other purposes,
          such as data analysis, identifying usage trends, determining the
          effectiveness of our promotional campaigns and to evaluate and improve
          our Service, products, services, marketing and your experience.
        </Paragraph>
        <Paragraph>
          We may share Your personal information in the following situations:
        </Paragraph>

        <ul>
          <li>
            ● With Service Providers: We may share Your personal information
            with Service Providers to monitor and analyze the use of our
            Service, to contact You.
          </li>
          <li>
            ● With Affiliates: We may share Your information with Our
            affiliates, in which case we will require those affiliates to honor
            this Privacy Policy. Affiliates include Our parent company and any
            other subsidiaries, joint venture partners or other companies that
            We control or that are under common control with Us.
          </li>
          <li>
            ● With other users: when You share personal information or otherwise
            interact in the public areas with other users, such information may
            be viewed by all users and may be publicly distributed outside.
          </li>
          <li>
            ● With separate consent from You, we may disclose Your personal
            information for other purposes.
          </li>
        </ul>

        <Title level={3}>Retention of Your Personal Data</Title>
        <Paragraph>
          We will retain your Personal Data for as long as your account is in
          active use and, thereafter, for no longer than one year.
        </Paragraph>

        <Paragraph>
          We will retain and use Your Personal Data to the extent necessary to
          comply with our legal obligations (for example, if we are required to
          retain your data to comply with applicable laws), resolve disputes,
          and enforce our legal agreements and policies.
        </Paragraph>

        <Paragraph>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </Paragraph>

        <Paragraph>
          We have the right to delete all of your Personal Data and other data
          after termination of your user account without notice to you.
        </Paragraph>

        <Paragraph>
          Even if you delete your account, keep in mind that the deletion by our
          Service Providers may not be immediate and that the deleted
          information may persist in backup copies for a reasonable period of
          time.
        </Paragraph>

        <Paragraph>
          We may retain De-Identified Personal Data for as long as we deem
          appropriate.
        </Paragraph>

        <Title level={3}>Transfer of Your Personal Data</Title>
        <Paragraph>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </Paragraph>
        <Paragraph>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </Paragraph>
        <Paragraph>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </Paragraph>

        <Title level={3}>Delete Your Personal Data</Title>
        <Paragraph>
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </Paragraph>
        <Paragraph>
          Following the deletion of Your Personal Data, we will De-Identify and
          retain the Data for as long as we deem appropriate.
        </Paragraph>
        <Paragraph>
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </Paragraph>
        <Paragraph>
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </Paragraph>
        <Paragraph>
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </Paragraph>

        <Title level={3}>Law enforcement</Title>
        <Paragraph>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </Paragraph>

        <Title level={3}>Security of Your Personal Data</Title>
        <Paragraph>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </Paragraph>

        <Title level={3}>Children's Privacy</Title>
        <Paragraph>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </Paragraph>
        <Paragraph>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </Paragraph>

        <Title level={3}>Changes to this Privacy Policy</Title>
        <Paragraph>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </Paragraph>
        <Paragraph>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </Paragraph>

        <Paragraph>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Paragraph>

        <Title level={3}>Contact Us</Title>
        <Paragraph>
          If you have any questions about this Privacy Policy, You can contact
          our Data Protection Officer: By email:{" "}
          <a href="mailto:dpo@godoecosystem.com">dpo@godoecosystem.com</a>.
        </Paragraph>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
