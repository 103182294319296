import { call, put } from "redux-saga/effects";
import { actions as newGodoActions } from "./slice";
import * as newGodoService from "./services";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18next";
function* fetchNewGodoSaga({ payload }) {
const MySwal = withReactContent(Swal);
  try {
    const { data } = yield call(newGodoService.postNewGodo, payload);
    yield put(newGodoActions.fetchDataSuccess(data));
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "success",
      title:i18n.t("dashboard_new_godo_popup_title"),
    });

  } catch (err) {
    yield put(newGodoActions.fetchDataFailed(err));
  } finally {
    yield put(newGodoActions.fetchDataFulfilled());
  }
}

export { fetchNewGodoSaga };
