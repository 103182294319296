import { call, put } from "redux-saga/effects";
import { actions as oneGodoActions } from "./slice";
import * as oneGodoService from "./services";

function* fetchOneGodoSaga({ payload }) {
  try {
    const { data } = yield call(oneGodoService.getOneGodo, payload);
    yield put(oneGodoActions.fetchDataSuccess(data));
  } catch (err) {
    yield put(oneGodoActions.fetchDataFailed(err));
  } finally {
    yield put(oneGodoActions.fetchDataFulfilled());
  }
}

export { fetchOneGodoSaga };
