import TasksTable from "../../components/taskTable";
import "./style.css";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
function Tasks() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="tasks-wrapper">
      <div className="tasks-wrapper-head">
        <span className="tasks-title">{i18n.t("dashboard_all_tasks_title")}</span>
        <a onClick={()=>{navigate("/new-godo")}}>
          <PlusOutlined />
          {i18n.t("dashboard_table_add_new")}
        </a>
      </div>
      <TasksTable />
    </div>
  );
}
export default Tasks;
