import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {selectUserData} from "../../../store/modules/user/selector";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {selectDashboardData} from "../../../store/modules/dashboard/selector";
import {actions as dashboardActions} from "../../../store/modules/dashboard/slice";

function DashboardHear() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const data = useSelector(selectUserData);
    const dataDashboard = useSelector(selectDashboardData);
    useEffect(() => {
        if (!dataDashboard) dispatch(dashboardActions.fetchDataTrigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataDashboard]);

    if (data && dataDashboard) {
        return (
            <div className="dashboard-head">
                <div className="left">
                    {data.data.image ? <img src={data.data.image}/> :
                        <img src="/avatar.png" alt=""/>}
                    {/*<img src={data.data.image} alt="" />*/}
                    <div className="text">
                        <h1>
                            {i18n.t("dashboard_hello")}, {data.data.title}
                            {/*{i18n.t("dashboard_wish")}*/}
                        </h1>
                        <h2>{i18n.t("dashboard_stats_today")}</h2>
                    </div>
                </div>
                <div className="right">
                    <div className="item">
                        <p>{i18n.t("dashboard_task_for_today")}</p>
                        <span>{dataDashboard.tasks_for_today}</span>
                    </div>
                    <svg
                        width="2"
                        height="32"
                        viewBox="0 0 2 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 0.000762939V32.0008"
                            stroke="black"
                            strokeOpacity="0.06"
                        />
                    </svg>
                    <div className="item">
                        <p>{i18n.t("dashboard_closed_task_for_today")}</p>
                        <div className="wrapper">
                            <span>{dataDashboard.closed_tasks_for_today}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null; // Повернути null, поки дані не завантажені
    }
}

export default DashboardHear;
